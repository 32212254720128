import styled from "styled-components";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    width: fit-content;
    padding: 4px 8px;
    margin: 5px 0;
`
const Name = styled.span`
    line-height: 2rem;
    margin-right: 10px;
`

const StyledButton = styled.button`
    border: none;
    background: none;
    svg {
        color: green;
        cursor: pointer;
    }
`

const LuteceTarget = ({data, onSelect}) => {

    const onSelectTarget = (e) => {
        onSelect(data);
    }

    return (
        <Root >
            <Name>{`${data.description} (${data.mnemonic})`}</Name>
            <StyledButton data-id={data.idCourseType} data-mnemonic={data.mnemonic} data-numberlevel={data.numberLevel} data-description={data.description} onClick={onSelectTarget}><AddCircleIcon /></StyledButton>
        </Root>
    );
}

export default LuteceTarget;
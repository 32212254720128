import React from "react";
import styled from "styled-components";
import { Table, SearchInput, ExportButton } from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from "axios";
import { useIntl } from "react-intl";
import { API_URL } from "../config";
import { exportService } from "../utils/exportService";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default function AudiencesPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const intl = useIntl();

  const [search, setSearch] = React.useState("");

  const columnsTable = [
    { title: "ID", field: "id", width: "10%", order: true, hidden: false },
    {
      title: translate("email"),
      field: "email",
      order: true,
      width: "30%",
      hidden: false,
    },
    {
      title: translate("origin"),
      field: "origin",
      order: true,
      width: "30%",
      hidden: false,
      mobile: false,
    },
    {
      title: translate("createdAt"),
      field: "createdAt",
      order: true,
      width: "30%",
      hidden: false,
    },
    {
      title: translate("actions"),
      actions: true,
      width: "auto",
      hidden: false,
    },
  ];

  const handleViewItem = (data) => {
    /* navigate(`/admin/sections?page=${data.id}`); */
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL}/audiences/${data.id}`, { withCredentials: true })
        .then((result) => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const actionsTable = {
    /* view: {
      icon: theme.icons.view,
      tooltip: "Ver registro",
      onClick: handleViewItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.info,
      hidden: false,
    }, */
    delete: {
      icon: theme.icons.delete,
      tooltip: "Eliminar",
      onClick: handleDeleteItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
  };

  return (
    <Root>
      <Title color={theme.palette.principal}>{translate("audiences")}</Title>

      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_URL}/audiences`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                  },
                  withCredentials: true,
                })
                .then((audiences) => {
                  resolve(audiences.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        >
          <SearchInput
            search={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
          <ExportButton
            action={() => exportService("/audiences/export", "Audiencias.xlsx")}
          />
        </Table>
        {/* <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                >
                    <NewUser userData={editUser} handleClose={handleCloseEdit} />
                </Modal> */}
      </Content>
    </Root>
  );
}

export async function getTopSalesProduct(params) {
  return new Promise((resolve) => {
    const salesData = productSalesData
      .sort((a, b) => b.sales - a.sales)
      .slice(0, params?.limit ?? 10);
    setTimeout(() => {
      resolve(salesData);
    }, 2000);
  });
}

const productSalesData = [
  { product: "Sede A", sales: 500 },
  { product: "Sede B", sales: 450 },
  { product: "Sede C", sales: 400 },
  { product: "Sede D", sales: 380 },
  { product: "Sede E", sales: 350 },
  { product: "Sede F", sales: 320 },
  { product: "Sede G", sales: 300 },
  { product: "Sede H", sales: 280 },
  { product: "Sede I", sales: 260 },
  { product: "Sede J", sales: 240 },
  { product: "Sede K", sales: 220 },
];

import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { formatTimestamp } from "../../utils/dateUtils";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const Root = styled.table`
  width: 100%;
`;

const AssignedCoursesHead = styled.thead`
  background: ${(props) => props.theme.palette.principal};
`;

const AssignedCoursesBody = styled.tbody``;

const AssignedCoursesHeaderRow = styled.tr`
  
`;

const AssignedCoursesRow = styled.tr`
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
`;

const AssignedCoursesColumn = styled.th`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.base};
  padding: 8px 0;
`;

const AssignedCoursesCell = styled.td`
  text-align: center;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  svg {
    color: red;
    cursor: pointer;
  }
`;

const AssignedCourses = ({ data, onDelete }) => {
  const onDeleteTarget = (e) => {
    const { id } = e.currentTarget.dataset;
    onDelete(id);
  };

  return (
    <Root>
      <AssignedCoursesHead>
        <AssignedCoursesHeaderRow>
          <AssignedCoursesColumn>idLUTECE</AssignedCoursesColumn>
          <AssignedCoursesColumn>{translate("code")}</AssignedCoursesColumn>
          <AssignedCoursesColumn>{translate("name")}</AssignedCoursesColumn>
          <AssignedCoursesColumn>
            {translate("headquarter")}
          </AssignedCoursesColumn>
          <AssignedCoursesColumn>{translate("turn")}</AssignedCoursesColumn>
          <AssignedCoursesColumn>
            {translate("frequency")}
          </AssignedCoursesColumn>
          <AssignedCoursesColumn>
            {translate("classroom")}
          </AssignedCoursesColumn>
          <AssignedCoursesColumn>
            {translate("initialDate")}
          </AssignedCoursesColumn>
          <AssignedCoursesColumn>{translate("endDate")}</AssignedCoursesColumn>
          <AssignedCoursesColumn>{translate("quota")}</AssignedCoursesColumn>
        </AssignedCoursesHeaderRow>
      </AssignedCoursesHead>
      <AssignedCoursesBody>
        {data.map((course, index) => (
          <AssignedCoursesRow
            key={`assigned_course_${index}_${course.courseCode}`}
          >
            <AssignedCoursesCell>{course.idLutece}</AssignedCoursesCell>
            <AssignedCoursesCell>{course.courseCode}</AssignedCoursesCell>
            <AssignedCoursesCell>{course.rename}</AssignedCoursesCell>
            <AssignedCoursesCell>{course.headquarter}</AssignedCoursesCell>
            <AssignedCoursesCell>{course.turn}</AssignedCoursesCell>
            <AssignedCoursesCell>{course.frequency}</AssignedCoursesCell>
            <AssignedCoursesCell>{course.classroom}</AssignedCoursesCell>
            <AssignedCoursesCell>
              {formatTimestamp(course.initialDate)}
            </AssignedCoursesCell>
            <AssignedCoursesCell>
              {formatTimestamp(course.endDate)}
            </AssignedCoursesCell>
            <AssignedCoursesCell>{course.quota}</AssignedCoursesCell>
            <AssignedCoursesCell>
              <StyledButton data-id={course.id} onClick={onDeleteTarget}>
                <RemoveCircleIcon />
              </StyledButton>
            </AssignedCoursesCell>
          </AssignedCoursesRow>
        ))}
      </AssignedCoursesBody>
    </Root>
  );
};

export default AssignedCourses;

import React from "react";
import axios from "axios";
import translate from "../../providers/i18n/translate";
import styled from "styled-components";
import { API_URL } from "../../config";
import { ThemeContext } from "../../providers/theme";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 20px;
`;

const Attachment = styled.a`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  cursor: pointer;
`;

const CancelButton = styled.button`
  border: none;
  background: none;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  cursor: pointer;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const UploadFile = ({ id, name, file, onChange, required }) => {
  const theme = React.useContext(ThemeContext);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      let formdata = new FormData();
      formdata.append("attachments", file);
      const url = `${API_URL}/upload`;
      axios
        .post(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          onChange(name, response.data.url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    onChange(name, undefined);
  };

  const onInputClick = (event) => {
    event.target.value = ''
  }


  return (
    <Root>
      <input
        id={id ?? "contained-upload-file"}
        name={name}
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        required={required}
        onClick={onInputClick}
      />
      {file ? (
        <Attachment href={file} target="_blank" download>
          {theme.icons.attachFile}
          {translate("attachment")}
        </Attachment>
      ) : (
        <span>{translate("file not selected")}</span>
      )}
      <Actions>
        <StyledLabel htmlFor={id ?? "contained-upload-file"}>
          {file ? translate("change") : translate("choose file")}
        </StyledLabel>
        {file ? (
          <CancelButton onClick={handleReset}>
            {translate("remove")}
          </CancelButton>
        ) : null}
      </Actions>
    </Root>
  );
};

export default UploadFile;

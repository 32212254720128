import {
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import { useService } from "../../../../hooks/useService";
import { getAnualSales } from "../../services/get-anual-sales";
import { VerticalBarchartLoading } from "../../../loadings/vertical-barchart-loading";

export const BarChartSales = () => {
  const { data, loading, error } = useService(getAnualSales, undefined);

  if (loading) {
    return <VerticalBarchartLoading />;
  }

  return !error ? (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 5 }}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="month" />
        <YAxis type="number" tickFormatter={(value) => `${value}`} />
        <Tooltip />
        <Bar
          dataKey="contacts"
          fill="#336699"
          barSize={50}
          animationEasing="ease-in-out"
        >
          <LabelList
            dataKey="contacts"
            position="top"
            formatter={(value) => `${value}`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  ) : null;
};

import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import axios from "axios";
import { API_URL_LUTECE } from "../../config";
import SelectInput from "../inputs/SelectInput";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.accent};
  border: none;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
  cursor: pointer;
`;

const AssignGroupToLevel = ({ selectedLevel, handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const { token } = useAuth();
  const userData = decodeToken(token);

  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [groupList, setGroupList] = React.useState(null);

  const getGroupList = () => {
    axios
      .get(`${API_URL_LUTECE}/groups`)
      .then((result) => {
        setGroupList(result.data.rows.map(g => {
          return {
            name: g.name,
            value: g.id
          }
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAutocomplete = (name, value) => {
    setSelectedGroup(value);
  };

  const handleSubmit = () => {
    if(selectedLevel){
      const formdata = {
        group: selectedGroup.value,
        updateUser: userData?.id,
      };
      axios
        .put(`${API_URL_LUTECE}/levels/assigngroup/${selectedLevel}`, formdata, {})
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "group assigned" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            handleClose();
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  React.useEffect(() => {
    getGroupList();
  }, []);

  return (
    <Root>
      {groupList ? (
        <SelectInput
          value={selectedGroup}
          fieldlabel="name"
          fieldvalue="value"
          data={groupList}
          label={intl.formatMessage({ id: "groups" })}
          name="selectedGroup"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          required
        />
      ) : (
        <span>Cargando...</span>
      )}

      <StyledButton onClick={handleSubmit}>{translate("add")}</StyledButton>
    </Root>
  );
};

export default AssignGroupToLevel;

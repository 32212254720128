import styled, { keyframes } from "styled-components";

const spin = keyframes`
  to {
        transform: rotate(360deg);
    }
`;

const LoadingTextLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem /* 8px */;
`;

const LoadingMessage = styled.span`
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
  font-weight: 700;
`;

const SvgWrapper = styled.div`
  svg {
    animation: ${spin} 1s linear infinite;
    height: 1.75rem /* 28px */;
    width: 1.75rem /* 28px */;
    margin-right: 0.75rem /* 12px */;
  }
  circle {
    opacity: 0.25;
  }
  path {
    opacity: 0.75;
  }
`;

export const LoadingText = () => {
  return (
    <LoadingTextLayout>
      <SvgWrapper>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </SvgWrapper>
      <LoadingMessage>Loading...</LoadingMessage>
    </LoadingTextLayout>
  );
};

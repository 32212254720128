import React from "react";
import styled from "styled-components";
import { Table, SearchInput, ExportButton } from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from "axios";
import { useIntl } from "react-intl";
import { API_URL } from "../config";
import { exportService } from "../utils/exportService";
import { Modal } from "@mui/material";
import ContactModalDetail from "../components/contacts/ContactModalDetail";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default function ContactsPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const intl = useIntl();

  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [contactData, setContactData] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    tableRef.current.clean();
    setContactData(null);
    setOpen(false);
  };

  const columnsTable = [
    { title: "ID", field: "id", width: "5%", order: true, hidden: false },
    {
      title: translate("name"),
      field: "contactData",
      order: false,
      width: "25%",
      hidden: false,
      render: (data) => {
        const parsedData = JSON.parse(data?.contactData);
        return `${parsedData?.firstname} ${parsedData?.lastname}`;
      },
    },
    {
      title: translate("email"),
      field: "email",
      order: true,
      width: "25%",
      hidden: false,
    },
    {
      title: translate("message"),
      field: "contactData",
      order: false,
      width: "25%",
      hidden: false,
      render: (data) => {
        const parsedData = JSON.parse(data?.contactData);
        return parsedData?.message;
      },
    },
    {
      title: translate("phone"),
      field: "contactData",
      order: false,
      width: "10%",
      hidden: false,
      mobile: false,
      render: (data) => {
        const parsedData = JSON.parse(data?.contactData);
        return parsedData?.phone;
      },
    },
    {
      title: translate("createdAt"),
      field: "createdAt",
      order: true,
      width: "10%",
      hidden: false,
    },
    {
      title: translate("actions"),
      actions: true,
      width: "auto",
      hidden: false,
    },
  ];

  const handleViewItem = (data) => {
    setContactData(JSON.parse(data.contactData));
    handleOpen();
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const actionsTable = {
    view: {
      icon: theme.icons.view,
      tooltip: "Ver registro",
      onClick: handleViewItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.info,
      hidden: false,
    },
  };

  return (
    <Root>
      <Title color={theme.palette.principal}>{translate("contacts")}</Title>

      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          orderTable={{
            field: "id",
            order: "DESC",
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_URL}/contacts`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                  },
                  withCredentials: true,
                })
                .then((contacts) => {
                  resolve(contacts.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        >
          <SearchInput
            search={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
          <ExportButton
            action={() => exportService("/contacts/export", "Contacts.xlsx")}
          />
        </Table>
        <Modal open={open} onClose={handleClose}>
          <ContactModalDetail
            contactData={contactData}
            handleClose={handleClose}
          />
        </Modal>
      </Content>
    </Root>
  );
}

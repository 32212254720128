import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import BasicEditor from "../textEditors/basicEditor";
import axios from "axios";
import { API_URL } from "../../config";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import InputError from "./InputError";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SelectInput from "../inputs/SelectInput";
import UploadFile from "../inputs/uploadFile";
import UploadImage from "../inputs/uploadImage";
import { slugify } from "../../utils/textUtils";
import CheckInput from "../inputs/CheckInput";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  @media (min-width: 1024px) {
    flex-direction: row;
    height: 50px;
    padding-bottom: 0;
  }
`;

const Title = styled.h2`
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 1.15rem;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
  }
`;

const FieldName = styled.span`
  color: ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  margin: 10px 0;
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 1024px) {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ActionButton = styled.button`
  background: transparent;
  border: none;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
  padding: 20px 0;
  overflow-y: auto;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 0.7fr 0.3fr;
    grid-template-rows: 0.4fr 0.6fr;
    gap: 20px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const GeneralData = styled(Box)`
  height: auto;
  @media (min-width: 1024px) {
    height: 100%;
  }
`;

const MetaData = styled(Box)``;

const ContentPost = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Slug = styled.small`
  color: ${(props) => props.theme.palette.details};
`;

const AmountCharacters = styled.div`
  width: auto;
  height: auto;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  margin: auto 0;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 600;
  background: ${props => props.numberCharacters < 250 ? "#FFF" : "red" };
  color: ${props => props.numberCharacters < 250 ? "#000" : "#FFF" };
`

const NewPost = () => {
  const { id } = useParams();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const intl = useIntl();
  let navigate = useNavigate();
  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const statusOptions = [
    {
      name: intl.formatMessage({ id: "published" }),
      value: "published",
    },
    {
      name: intl.formatMessage({ id: "draft" }),
      value: "draft",
    }
  ];

  const handleUpload = (name, file) => {
    setData({
      ...data,
      [name]: file,
    });
  };

  const [errors, setErrors] = React.useState({
    image: {
      value: false,
      text: intl.formatMessage({ id: "principal image must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
    title: {
      value: false,
      text: intl.formatMessage({ id: "title must not be empty" }),
      validate: (value) => value == null || value == "", // returns true if there is an error and false if there is not
    },
    resume: {
      value: false,
      text: intl.formatMessage({ id: "limit summary to 250 characters" }),
      validate: (value) => value.length > 250, // returns true if there is an error and false if there is not
    },
    category: {
      value: false,
      text: intl.formatMessage({ id: "category must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
    startDate: {
      value: false,
      text: intl.formatMessage({ id: "startDate must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
    endDate: {
      value: false,
      text: intl.formatMessage({ id: "endDate must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
    schedule: {
      value: false,
      text: intl.formatMessage({ id: "schedule must not be empty" }),
      validate: (value) => value == null || value == "", // returns true if there is an error and false if there is not
    },
    tag: {
      value: false,
      text: intl.formatMessage({ id: "tag must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
    status: {
      value: false,
      text: intl.formatMessage({ id: "status must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
  });

  const [data, setData] = React.useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      slug: name === "title" ? slugify(value) : data.slug,
      [name]: value,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setData({
      ...data,
      content: dataeditor,
    });
  };

  const back = () => {
    navigate(-1);
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeOutstanding = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  }

  const handleChangeDateStart = (newValue) => {
    setData({
      ...data,
      startDate: newValue,
    });
  };

  const handleChangeDateEnd = (newValue) => {
    setData({
      ...data,
      endDate: newValue,
    });
  };

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("preTitle", data.preTitle);
      formData.append("title", data.title);
      formData.append("postTitle", data.postTitle);
      formData.append("resume", data.resume);
      formData.append("content", data.content);
      formData.append("image", data.image);
      formData.append("thumbnail", data.thumbnail);
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("schedule", data.schedule);
      formData.append("category", typeof data.category === "object" ? data.category?.value : data.category);
      formData.append("center", typeof data.center === "object" ? data.center?.value : data.center);
      formData.append("place", data.place);
      formData.append("address", data.address);
      formData.append("contact", data.contact);
      formData.append("phone", data.phone);
      formData.append("price", data.price);
      formData.append("tag", typeof data.tag === "object" ? data.tag?.value : data.tag);
      formData.append("attachment", data.attachment);
      formData.append("outstanding", data.outstanding);
      formData.append("status", typeof data.status === "object" ? data.status?.value : data.status);

      if (id) {
        formData.append("updatedUser", userData?.id);
        axios
          .put(`${API_URL}/events/${id}`, formData, {})
          .then((res) => {
            alertMessage(
              intl.formatMessage({ id: "event updated" }),
              alertConstants.INFO
            );
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        formData.append("createdUser", userData?.id);
        formData.append("slug", data.slug);
        axios
          .post(`${API_URL}/events`, formData, {})
          .then((res) => {
            alertMessage(
              intl.formatMessage({ id: "event created" }),
              alertConstants.INFO
            );
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      }
    }
  };

  React.useEffect(() => {
    if (!data) {
      if (id !== undefined && id !== null) {
        axios
          .get(`${API_URL}/events/${id}`)
          .then((res) => {
            const event = res.data;
            setData({
              preTitle: event.preTitle,
              title: event.title,
              postTitle: event.postTitle,
              resume: event.resume ?? "",
              content: event.content,
              image: event.image,
              thumbnail: event.thumbnail,
              startDate: event.startDate,
              endDate: event.endDate,
              schedule: event.schedule,
              category: typeof event.category === "object" ? event.category?.id : event.category,
              center: typeof event.center === "object" ? event.center?.id : event.center,
              place: event.place,
              address: event.address,
              contact: event.contact,
              phone: event.phone,
              price: event.price,
              tag: typeof event.tag === "object" ? event.tag?.id : event.tag,
              attachment: event.attachment,
              status: event.status,
              outstanding: event.outstanding
            });
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        setData({
          preTitle: "",
          title: "",
          postTitle: "",
          resume: "",
          content: "",
          image: null,
          thumbnail: null,
          startDate: null,
          endDate: null,
          schedule: "",
          category: null,
          center: null,
          place: "",
          address: "",
          contact: "",
          phone: "",
          price: "",
          tag: null,
          attachment: null,
          status: null,
          outstanding: false
        });
      }
    }
    if (data) {
      setEditorLoaded(true);
    }
  }, [data]);

  return (
    data && (
      <Root>
        <Header>
          <Title>{id ? translate("edit post") : translate("new post")}</Title>
          <Actions>
            <ActionButton onClick={submit}>{translate("apply")}</ActionButton>
            <ActionButton onClick={back}>{translate("cancel")}</ActionButton>
          </Actions>
        </Header>
        <Container>
          <GeneralData>
            <StyledTextfield
              id="preTitle"
              name="preTitle"
              onChange={handleChange}
              value={data.preTitle}
              label={intl.formatMessage({ id: "preTitle" })}
              variant="outlined"
              width="100%"
              margin="dense"
              size="small"
            />
            <InputData>
              <StyledTextfield
                id="title"
                name="title"
                onChange={handleChange}
                value={data.title}
                label={intl.formatMessage({ id: "title" })}
                variant="outlined"
                width="100%"
                margin="dense"
                size="small"
                required
              />
              <Slug>{data.slug}</Slug>
              <InputError show={errors.title.value}>
                {errors.title.text}
              </InputError>
            </InputData>
            <StyledTextfield
              id="postTitle"
              name="postTitle"
              onChange={handleChange}
              value={data.postTitle}
              label={intl.formatMessage({ id: "postTitle" })}
              variant="outlined"
              width="100%"
              margin="dense"
              size="small"
            />
            <InputGroup>
            <InputData>
            <StyledTextfield
              id="outlined-textarea"
              name="resume"
              onChange={handleChange}
              value={data.resume}
              label={intl.formatMessage({ id: "resume" })}
              placeholder={intl.formatMessage({ id: "insert a summary of the event" })}
              multiline
              width="100%"
              margin="dense"
              size="small"
              maxRows={4}
              helperText={intl.formatMessage({ id: "limit summary to 250 characters" })}
            />
            <InputError show={errors.resume.value}>
                {errors.resume.text}
              </InputError>
            </InputData>
            <AmountCharacters numberCharacters={data.resume.length}>{data.resume.length}</AmountCharacters>
            </InputGroup>

            <FieldName>{translate("post content")}</FieldName>
            <ContentPost>
              <BasicEditor
                name="content"
                value={data?.content}
                onChange={handleEditor}
                editorLoaded={editorLoaded}
                maxContentHeight="400px"
                minContentHeight="300px"
                placeholder={intl.formatMessage({
                  id: "insert content here...",
                })}
              />
            </ContentPost>
          </GeneralData>
          <MetaData>
            <FieldName>{translate("thumbnail image")}</FieldName>
            <UploadImage
                attachWidth="100px"
                attachHeight="100px"
                id="thumbnail-upload"
                name="thumbnail"
                onChange={handleUpload}
                file={data.thumbnail}
                required
              />
              <FieldName>{translate("principal image")}</FieldName>
            <InputData>
              <UploadImage
                id="image-upload"
                name="image"
                onChange={handleUpload}
                file={data.image}
                required
              />
              <InputError show={errors.image.value}>
                {errors.image.text}
              </InputError>
            </InputData>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <InputGroup>
                <InputData>
                  <DatePicker
                    label={intl.formatMessage({ id: "startDate" })}
                    value={data?.startDate}
                    onChange={handleChangeDateStart}
                    renderInput={(params) => (
                      <TextField margin="dense" {...params} />
                    )}
                  />
                  <InputError show={errors.startDate.value}>
                    {errors.startDate.text}
                  </InputError>
                </InputData>
                <InputData>
                  <DatePicker
                    label={intl.formatMessage({ id: "endDate" })}
                    value={data?.endDate}
                    onChange={handleChangeDateEnd}
                    renderInput={(params) => (
                      <TextField margin="dense" {...params} />
                    )}
                  />
                  <InputError show={errors.endDate.value}>
                    {errors.endDate.text}
                  </InputError>
                </InputData>
              </InputGroup>
            </LocalizationProvider>
            <InputData>
              <StyledTextfield
                id="schedule"
                name="schedule"
                onChange={handleChange}
                value={data.schedule}
                label={intl.formatMessage({ id: "schedule" })}
                variant="outlined"
                width="100%"
                margin="normal"
                size="small"
              />
              <InputError show={errors.schedule.value}>
                {errors.schedule.text}
              </InputError>
            </InputData>
            <InputGroup>
            <InputData>
              <SelectInput
                value={data.status}
                fieldlabel="name"
                fieldvalue="value"
                data={statusOptions}
                label={intl.formatMessage({ id: "status" })}
                name="status"
                onChange={handleChangeAutocomplete}
                width="100%"
                margin="dense"
                size="small"
                required
              />
              <InputError show={errors.status.value}>
                {errors.status.text}
              </InputError>
            </InputData>
            <CheckInput
              checked={data.outstanding}
              onChange={handleChangeOutstanding}
              label={intl.formatMessage({ id: "outstanding" })}
              name="outstanding"
              width="100%"
            />
            </InputGroup>
            <InputData>
              <SelectInput
                value={data.category}
                fieldlabel="name"
                fieldvalue="id"
                dataurl={`${API_URL}/categories/list`}
                label={intl.formatMessage({ id: "category" })}
                name="category"
                onChange={handleChangeAutocomplete}
                width="100%"
                margin="dense"
                size="small"
                required
              />
              <InputError show={errors.category.value}>
                {errors.category.text}
              </InputError>
            </InputData>
            <SelectInput
              value={data.center}
              fieldlabel="name"
              fieldvalue="id"
              dataurl={`${API_URL}/centers/list`}
              label={intl.formatMessage({ id: "center" })}
              name="center"
              onChange={handleChangeAutocomplete}
              width="100%"
              margin="dense"
              size="small"
            />
            <StyledTextfield
              id="place"
              name="place"
              onChange={handleChange}
              value={data.place}
              label={intl.formatMessage({ id: "place" })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
            />
            <StyledTextfield
              id="address"
              name="address"
              onChange={handleChange}
              value={data.address}
              label={intl.formatMessage({ id: "address" })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
            />
            <StyledTextfield
              id="contact"
              name="contact"
              onChange={handleChange}
              value={data.contact}
              label={intl.formatMessage({ id: "contact" })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
            />
            <StyledTextfield
              id="phone"
              name="phone"
              onChange={handleChange}
              value={data.phone}
              label={intl.formatMessage({ id: "phone" })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
            />
            <StyledTextfield
              id="price"
              name="price"
              onChange={handleChange}
              value={data.price}
              label={intl.formatMessage({ id: "price" })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
            />
            <InputData>
              <SelectInput
                value={data.tag}
                fieldlabel="name"
                fieldvalue="id"
                dataurl={`${API_URL}/tags/list`}
                label={intl.formatMessage({ id: "tag" })}
                name="tag"
                onChange={handleChangeAutocomplete}
                width="100%"
                margin="dense"
                size="small"
              />
              <InputError show={errors.tag.value}>{errors.tag.text}</InputError>
            </InputData>
            <UploadFile
              id="attachment-upload"
              name="attachment"
              onChange={handleUpload}
              file={data.attachment}
            />
          </MetaData>
        </Container>
      </Root>
    )
  );
};

export default NewPost;

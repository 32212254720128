import { LoadingLayout } from "./loading-layout";
import { LoadingText } from "./loading-text";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  50% {
      opacity: .5;
  }
`;

const verticalAnimate = keyframes`
 0%, 100% {
      height: 25%;
  }
  50% {
      height: 75%;
  }
  75% {
      height: 100%;
  }
`;

const VerticalBarchartLoadingWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  margin: 0 2px;
  align-items: end;
  margin: auto;
  height: 6rem /* 96px */;
  width: 8rem /* 128px */;
`;

const Skeleton = styled.div`
  flex: 1 1 0%;
  background-color: aliceblue;
  border-radius: 0.25rem /* 4px */;
  animation: ${verticalAnimate} 1500ms ease-in-out infinite;
`;

export const VerticalBarchartLoading = () => {
  return (
    <LoadingLayout>
      <VerticalBarchartLoadingWrapper>
        <SkeletonWrapper>
          {[0, 150, 75, 500, 200].map((bar, index) => {
            return <Skeleton key={`skeleton_bar_${index}`}></Skeleton>;
          })}
        </SkeletonWrapper>
        <LoadingText />
      </VerticalBarchartLoadingWrapper>
    </LoadingLayout>
  );
};

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyIcon from '@mui/icons-material/Key';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CloseIcon from '@mui/icons-material/Close';

export const THEMES = {
    default: {
        palette: {
            principal: "#081f32",
            base: "#FFFFFF",
            accent: "#DA002E",
            details: "#707070",
            error: "#d32f2f",
            success: "#2e7d32",
            info: "#0288d1",
            warning: "#ed6c02"
        },
        icons: {
            view: <VisibilityIcon />,
            edit: <EditIcon />,
            delete: <DeleteForeverIcon />,
            password: <KeyIcon />,
            attachFile: <AttachFileIcon />,
            check: <CheckIcon />,
            settings: <SettingsIcon />,
            add: <AddIcon />,
            arrowUp: <ArrowDropUpIcon />,
            arrowDown: <ArrowDropDownIcon />,
            text: <FormatAlignJustifyIcon />,
            close: <CloseIcon />
        }
    }
}


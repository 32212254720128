import styled from "styled-components";

const Root = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SignatureLayout = ({ children }) => {
  return (
    <Root>
      {children}
    </Root>
  );
};

export default SignatureLayout;

import styled from "styled-components";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from '@mui/icons-material/Edit';
import { useRef, useState } from "react";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  width: fit-content;
  padding: 4px 8px;
  margin: 5px 0;
  transition: all 400ms ease-in-out;
`;
const Name = styled.span`
  line-height: 2rem;
  margin-right: 10px;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  margin-left: 10px;
  svg {
    color: ${(props) =>
      props.colorbutton ? props.colorbutton : props.theme.palette.principal};
    cursor: pointer;
  }
`;

const RenameContainer = styled.div`
    width: ${props => props.show ? "auto" : 0};
    opacity: ${props => props.show ? 1 : 0};
`;

const RenameInput = styled.input`
    line-height: 1.5rem;
    margin-left: 10px;
    padding: 0 10px;
    border: none;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    &:focus {
        box-shadow: 0 0 2px ${props => `${props.theme.palette.principal}50`};
    }
`;

const SelectedTarget = ({ data, onDelete, onRename }) => {
  const [rename, setRename] = useState(data.rename ?? `${data.name} (${data.mnemonic})`);
  const [showRename, setShowRename] = useState(false);

  const inputRef = useRef();

  const onDeleteTarget = (e) => {
    onDelete(data.id);
  };

  const onEditName = () => {
    setShowRename(!showRename);
    inputRef.current.focus();
  }

  const onChangeRename = (e) => {
    const { value } = e.target;
    
    setRename(value);
  };

  const handleKeyPress = (e) => {
    const key = e.keyCode || e.which;
    if(key === 13){
        e.preventDefault();
        onRename(data.id, rename);
        setShowRename(false);
    } else if(key === 27){
        e.preventDefault();
        setRename(data.rename ?? `${data.name} (${data.mnemonic})`);
        setShowRename(false);
    }
  }

  return (
    <Root>
      <Name>{data.rename ?? `${data.name} (${data.mnemonic})`}</Name>
      <StyledButton colorbutton="red" onClick={onDeleteTarget}>
        <RemoveCircleIcon />
      </StyledButton>
      <StyledButton onClick={onEditName}>
        <EditIcon />
      </StyledButton>
      <RenameContainer show={showRename}>
        <RenameInput
          ref={inputRef}
          id={`target_rename_input_${data.id}`}
          type="text"
          name="rename"
          value={rename}
          onChange={onChangeRename}
          onKeyDown={handleKeyPress}
        />
      </RenameContainer>
    </Root>
  );
};

export default SelectedTarget;

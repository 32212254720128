import React from "react";
import styled from "styled-components";
/* import translate from "../providers/i18n/translate"; */
import { useAuth } from "../providers/authentication";
import { decodeToken } from "../hooks/jwt";
import Dashboard from "../components/dashboard/dashboard";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
`;

export default function AdminPage() {
  const { token } = useAuth();

  const userData = decodeToken(token);

  return (
    <Root>
      <div style={{width: "100%", background: "lightblue", padding: "5px", textAlign: "center"}}>ESTOS DATOS SON DE PRUEBA PARA EL DISEÑO DEL NUEVO DASHBOARD</div>
      <Dashboard />
    </Root>
  );
}

import React from 'react';
import axios from "axios";
import SelectInput from "../inputs/SelectInput";
import styled from "styled-components";
import { API_URL } from '../../config';
import { useIntl } from 'react-intl';

const Root = styled.div``;

const ReservationState = ({ idReservation }) => {

    const intl = useIntl();
  const [currentState, setCurrentState] = React.useState();

  const stateOptions = [
    {
      name: "Solicitada",
      value: "requested",
    },
    {
      name: "Completada",
      value: "completed",
    },
    {
      name: "Libre",
      value: "free",
    },
  ];

  const getState = () => {
    axios
      .get(`${API_URL}/reservations/status/${idReservation}`)
      .then((reservationStatus) => {
        setCurrentState(reservationStatus.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAutocomplete = (name, value) => {
    let formdata = new FormData();
    formdata.append("status", value.value);
    formdata.append("updatedUser", 1);
    axios
      .put(`${API_URL}/reservations/status/${idReservation}`, formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        setCurrentState(value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (!currentState) {
      getState();
    }
  }, [idReservation]);

  return currentState ? (
    <Root>
      <SelectInput
        value={currentState}
        fieldlabel="name"
        fieldvalue="value"
        data={stateOptions}
        label={intl.formatMessage({ id: "status" })}
        name="status"
        onChange={handleChangeAutocomplete}
        width="100%"
        margin="dense"
        size="small"
      />
    </Root>
  ) : null;
};

export default ReservationState;

import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from "../../../../providers/theme";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const Root = styled.div`
    position: relative;
`

const StyledIcon = styled(FilterAltIcon)`
    cursor: pointer;
    margin: 0 10px;
    color: ${(props) => props.color ? props.color : '#000'};
`

const FilterList = styled.div`
    position: absolute;
    top: 100%;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #f1f1f1;
    width: 200px;
    z-index: 9;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    border-radius: 8px 0 8px 8px;
    transition: all 300ms ease-in-out;
    padding: ${(props) => props.hide ? 0 : "5px"};
    height: ${(props) => props.hide ? 0 : "auto"};
    overflow-y: ${(props) => props.hide ? "hidden" : "auto"};
    opacity: ${(props) => props.hide ? 0 : 1};
`

const ApplyFilter = styled.button`
    align-self: flex-end;
    color: ${(props) => props.color ? props.color : "blue"};
    border: none;
    outline: none;
    text-decoration: underline;
    margin: 5px 10px 5px 0;
    cursor: pointer;
`

const Filters = ({children, action}) => {

    const theme = React.useContext(ThemeContext);

    const [ hide, setHide ] = React.useState(true);

    const handleHide = (e) => {
        e.preventDefault();
        setHide(!hide);
    }

    const handleAction = (e) => {
        e.preventDefault();
        action();
        setHide(true);
    }

    return (
        <Root>
            <StyledIcon onClick={handleHide} color={theme.palette.principal} />
            <FilterList hide={hide}>
                {children}
                <ApplyFilter onClick={handleAction}>Aplicar</ApplyFilter>
            </FilterList>
        </Root>
    );
}

export default Filters;
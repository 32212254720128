import React from "react";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";

const Root = styled.th`
    width: ${(props) => props.width ? props.width : "auto"};
    display: ${(props) => props.hidden ? "none" : props.hiddenMobile ? "none" : "table-cell"};
    text-align: ${(props) => props.align ? props.align : "left"};
    border-bottom: 2px solid #C6C6C6;
    line-height: 2rem;
    color: ${(props) => props.color ? props.color : "#000"};
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 0.875rem;
    position: sticky;
    top: 0;
    background-color: #FFF;
    @media (min-width: 400px) {
        display: ${(props) => props.hidden ? "none" : "table-cell"};
    }
`

const HeaderCell = ({children, width, hidden, hiddenMobile, align, color}) => {
    return (
        <Root width={width} hidden={hidden} hiddenMobile={hiddenMobile} align={align} color={color}>
            {children}
        </Root>   
    );
}

export default HeaderCell;
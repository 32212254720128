import React, { forwardRef } from "react";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import Head from "./Head";
import Body from "./Body";
import GridBody from "./GridBody";
import AccordionBody from "./AccordionBody";
import Foot from "./Foot";
import Pagination from "./Pagination";
import { ThemeContext } from "../../../providers/theme";
import SearchInput from "./SearchInput";
import { Filters } from "./";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ListIcon from "@mui/icons-material/List";
import ExportButton from "./ExportButton";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (min-width: 400px) {
    grid-template-rows: 50px 1fr 50px;
  }
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
`;

const ContentTable = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const StyledTable = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
`;

const TableType = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  margin: 0 10px;
`;

const View = styled.button`
  border: none;
  color: ${(props) => (props.active ? props.color : "rgba(0,0,0,0.5)")};
  background: transparent;
  cursor: pointer;
  user-select: none;
`;

const Divider = styled.div`
  width: 1px;
  height: 1rem;
  background-color: ${(props) => (props.bg ? props.bg : "#000")};
  margin: 0 10px;
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  @media (min-width: 400px) {
    height: 50px;
  }
`;

const Table = React.forwardRef(
  (
    {
      columns,
      viewTable,
      disablePlain,
      changeView,
      gridCard,
      accordion,
      actionsTable,
      options,
      data,
      children,
      orderTable,
    },
    ref
  ) => {
    const theme = React.useContext(ThemeContext);

    const [dataset, setDataset] = React.useState(null);

    const [total, setTotal] = React.useState(null);

    const [originalDataset, setOriginalDataset] = React.useState(null);

    const [_options, setOptions] = React.useState(
      options
        ? {
            pageSize:
              options.pageSize || options.pageSizeOptions
                ? options.pageSizeOptions[0]
                : 20,
            pageSizeOptions: options.pageSizeOptions || [20, 50, 100],
            order: columns
              ? {
                  field: columns[0].field,
                  order: "ASC",
                }
              : null,
            exportButton: options.exportButton || false,
            exportAllData: options.exportAllData || false,
            pagination: options.pagination,
          }
        : {
            order: orderTable
              ? orderTable
              : columns
              ? {
                  field: columns[0].field,
                  order: "ASC",
                }
              : null,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            exportButton: false,
            exportAllData: false,
            pagination: true,
          }
    );

    const [currentPage, setCurrentPage] = React.useState(1);

    const onChangeView = (e) => {
      e.preventDefault();
      const { view } = e.currentTarget.dataset;
      if (changeView) {
        changeView(view);
        if (view === "list" && !disablePlain) {
          const flatArray = dataset.map((no) => flattObj(no, ""));
          setDataset(flatArray);
        } else {
          setDataset(originalDataset);
        }
      }
    };

    const handleLimit = (newLimit) => {
      setOptions({
        ..._options,
        pageSize: newLimit,
      });
      setCurrentPage(1);
      refresh(1, newLimit);
    };

    const handlePage = (page) => {
      setCurrentPage(page);
    };

    const onNext = () => {
      const maxPage = total ? Math.ceil(total / _options.pageSize) : 0;
      if (maxPage > currentPage) {
        setCurrentPage(parseInt(currentPage) + 1);
        refresh(parseInt(currentPage) + 1);
      }
    };

    const onPrevios = () => {
      const maxPage = total ? Math.ceil(total / _options.pageSize) : 0;
      if (maxPage > currentPage - 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
        refresh(parseInt(currentPage) - 1);
      }
    };

    const refresh = (newPage, newLimit) => {
      data({
        page: newPage ? newPage : currentPage,
        pageSize: newLimit ? newLimit : _options.pageSize,
        order: _options.order,
      })
        .then((result) => {
          if (viewTable && viewTable === "list" && !disablePlain) {
            const flatArray = result.rows.map((no) => flattObj(no, ""));
            setDataset(flatArray);
          } else {
            setDataset(result.rows);
          }
          setOriginalDataset(result.rows);
          setTotal(result.total ? result.total : null);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleOrder = (field, order) => {
      setOptions({
        ..._options,
        order: {
          field: field,
          order: order,
        },
      });
      data({
        page: currentPage,
        pageSize: _options.pageSize,
        search: _options.search,
        order: {
          field: field,
          order: order,
        },
      })
        .then((result) => {
          if (viewTable && viewTable === "list" && !disablePlain) {
            const flatArray = result.rows.map((no) => flattObj(no, ""));
            setDataset(flatArray);
          } else {
            setDataset(result.rows);
          }
          setOriginalDataset(result.rows);
          setTotal(result.total ? result.total : null);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const searchChildren = React.Children.map(children, (child) => {
      if (child.type === SearchInput) {
        return React.cloneElement(child);
      }
      return null;
    });

    const filterChildren = React.Children.map(children, (child) => {
      if (child.type === Filters) {
        return React.cloneElement(child);
      }
      return null;
    });

    const exportChildren = React.Children.map(children, (child) => {
      if (child.type === ExportButton) {
        return React.cloneElement(child);
      }
      return null;
    });

    function flattObj(obj, param) {
      let newObj = {};
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          newObj = { ...newObj, ...flattObj(obj[key], key + ".") };
        } else {
          newObj[param + key] = obj[key];
        }
      }
      return newObj;
    }

    React.useEffect(() => {
      if (!dataset) {
        if (data) {
          data({
            page: currentPage,
            pageSize: _options.pageSize,
            order: _options.order,
          })
            .then((result) => {
              if (viewTable && viewTable === "list" && !disablePlain) {
                const flatArray = result.rows.map((no) => flattObj(no, ""));
                setDataset(flatArray);
              } else {
                setDataset(result.rows);
              }
              setOriginalDataset(result.rows);
              setTotal(result.total ? result.total : null);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setDataset([]);
        }
      }
    }, [dataset, viewTable]);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    React.useImperativeHandle(ref, () => ({
      clean() {
        refresh();
      },
    }));

    return (
      <Root>
        <Header>
          {changeView && (
            <TableType>
              <View
                data-view={"grid"}
                onClick={onChangeView}
                active={viewTable === "grid"}
                color={theme.palette.principal}
              >
                <ViewComfyIcon />
              </View>
              <Divider />
              <View
                data-view={"list"}
                onClick={onChangeView}
                active={viewTable === "list"}
                color={theme.palette.principal}
              >
                <ListIcon />
              </View>
            </TableType>
          )}
          {searchChildren}
          {filterChildren}
          {exportChildren}
        </Header>
        <ContentTable>
          <StyledTable>
            {viewTable === "list" && (
              <Head columnsTable={columns} onOrder={handleOrder} />
            )}
            {viewTable === "list" ? (
              <Body
                rowsTable={dataset}
                rawData={originalDataset}
                columnsTable={columns}
                actions={actionsTable}
              />
            ) : viewTable === "accordion" ? (
              <AccordionBody
                rowsTable={dataset}
                accordion={accordion}
                actions={actionsTable}
              />
            ) : (
              <GridBody
                rowsTable={dataset}
                gridCard={gridCard}
                actions={actionsTable}
              />
            )}
          </StyledTable>
        </ContentTable>
        <Footer>
          <Pagination
            total={total}
            show={_options.pagination}
            pageOptions={_options.pageSizeOptions}
            limit={_options.pageSize}
            refresh={refresh}
            changeLimit={handleLimit}
            currentPage={currentPage}
            changePage={handlePage}
            pages={total ? Math.ceil(total / _options.pageSize) : 0}
            previos={onPrevios}
            next={onNext}
          />
        </Footer>
      </Root>
    );
  }
);

export default Table;

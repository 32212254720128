export const formatDate = (input) => {
    const dateToTransform = input.split('-');
    if(dateToTransform.length === 3){
        return `${dateToTransform[2]}/${dateToTransform[1]}/${dateToTransform[0]}`
    }
    return null;
}

export const formatTimestamp = (input) => {
    const dateToTransform = input.toUpperCase().split('T');
    if(dateToTransform.length === 2){
        return formatDate(dateToTransform[0]);
    }
    return null;
}

export const getDayFromDate = (input) => {
    const dateToTransform = input.split('-');
    if(dateToTransform.length === 3){
        return input[0]
    }
    return null;
}

export const getMonthFromDate = (input) => {
    const dateToTransform = input.split('-');
    if(dateToTransform.length === 3){
        return input[1]
    }
    return null;
}

export const getYearFromDate = (input) => {
    const dateToTransform = input.split('-');
    if(dateToTransform.length === 3){
        return input[3]
    }
    return null;
}

export const getHourFromTime = (input) => {
    const timeToTransform = input.split(':');
    return timeToTransform[0];
}

export const getMinutesFromTime = (input) => {
    const timeToTransform = input.split(':');
    return timeToTransform[1];
}

export const getStringDate = (input) => {
    return `${input.getFullYear()}-${pad(input.getMonth() + 1)}-${pad(input.getDate())}`;
}

const pad = (num) => {
    if(num < 10){
        return `0${num}`;
    }
    return `${num}`;
}
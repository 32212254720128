import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";

/* Styled components */
const Root = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const StyledTextfield = styled(TextField)`
  width: ${(props) => (props.width ? props.width : "inherit")};
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.accent};
  border: none;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
  cursor: pointer;
`;

const SaveSignature = ({onSave, handleClose}) => {

    const intl = useIntl();

    const [saveas, setSaveas] = React.useState("");

    const handleChange = (e) => {
        const { value } = e.target;
        setSaveas(value)
      };

    const handleSave = (e) => {
        e.preventDefault();
        onSave(saveas);
        handleClose();
    }

    return (
        <Root onSubmit={handleSave}>
            <StyledTextfield
            id="saveas"
            name="saveas"
            onChange={handleChange}
            value={saveas}
            label={intl.formatMessage({ id: "save as" })}
            variant="outlined"
            width="100%"
            margin="dense"
            size="small"
            required
          />
          <StyledButton>{translate("save")}</StyledButton>
        </Root>
    );
}



export default SaveSignature;
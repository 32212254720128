export function slugify(text) {
    return text
      .normalize('NFKD')        // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase()            // Convert the string to lowercase letters
      .trim()                   // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, '-')     // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-');  // Replace multiple - with single -
}

export function checkIfValidURLSlug(str) {
  // Regular expression to check if string is a valid url slug
  const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;

  return regexExp.test(str);
}
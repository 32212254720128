import { API_URL } from "../config";

export function exportService(url, exportName) {
  fetch(`${API_URL}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/xml",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", exportName);

      document.body.appendChild(link);

      link.click();

      link?.parentNode?.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
    });
}

import { useService } from "../../../../../hooks/useService";
import { IndicatorLoading } from "../../../../loadings/indicator-loading";
import { formatNumberIndicator } from "../../../../../utils/numbers";
import styled from "styled-components";

const IndicatorLoadingLayout = styled.div`
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  width: 50%;
  height: 11rem /* 176px */;
  @media (min-width: 480px) {
    width: 25%;
  }
`;

const IndicatorLoadingWrapper = styled.div`
  padding: 0.5rem /* 8px */;
  height: 100%;
  border-radius: 0.5rem /* 8px */;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const IndicatorLayout = styled.div`
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  width: 50%;
  height: 11rem /* 176px */;
  @media (min-width: 480px) {
    width: 25%;
  }
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem /* 8px */;
  border-radius: 0.5rem /* 8px */;
  height: 100%;
  cursor: pointer;
  user-select: none;
  svg {
    width: 2.5rem /* 40px */;
    height: 2.5rem /* 40px */;
    margin-bottom: 0.25rem /* 4px */;
    display: inline-block;
  }
`;

const TitleIndicator = styled.h2`
  font-weight: 500;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
  @media (min-width: 480px) {
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
  }
`;

const DescriptionIndicator = styled.p`
  line-height: 1.625;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
`;

export const Indicator = ({ icon, description, service, onClick }) => {
  const { data: title, loading, error } = useService(service);
  const handleClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick({ icon, title, description });
    }
  };
  if (loading) {
    return (
      <IndicatorLoadingLayout>
        <IndicatorLoadingWrapper>
          <IndicatorLoading />
        </IndicatorLoadingWrapper>
      </IndicatorLoadingLayout>
    );
  }

  return !error && title ? (
    <IndicatorLayout>
      <IndicatorWrapper onClick={handleClick}>
        {icon}
        <TitleIndicator>{formatNumberIndicator(title)}</TitleIndicator>
        <DescriptionIndicator>{description}</DescriptionIndicator>
      </IndicatorWrapper>
    </IndicatorLayout>
  ) : null;
};

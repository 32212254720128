import styled from "styled-components";

const LoadingLayoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const LoadingLayout = ({ children }) => {
  return <LoadingLayoutWrapper>{children}</LoadingLayoutWrapper>;
};

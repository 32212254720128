import React from 'react';
import axios from "axios";
import { API_URL } from "../../config";
import styled from "styled-components";
import TestAccess from './TestAccess';
import { Tooltip } from '@mui/material';

const Root = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;



const AccessTests = ({ participant }) => {
  const INITIAL_TEST = 1;
  const SELECTED_TEST_1 = 2;
  const SELECTED_TEST_2 = 3;
  const SELECTED_TEST_3 = 4;
  const SELECTED_TEST_4 = 5;
  const CHALLENGE_TEST = 6;

  const [completedtests, setCompletedtests] = React.useState();

  const getTest = () => {
    axios
      .get(`${API_URL}/answers/quizzes?participant=${participant}`)
      .then((tests) => {
        setCompletedtests(tests.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getTest();
  }, [participant]);

  return completedtests ? (
    <Root>
      <TestAccess active={completedtests.find((t) => parseInt(t.id) === INITIAL_TEST)} participant={participant} testId={INITIAL_TEST} testData={completedtests.find((t) => parseInt(t.id) === INITIAL_TEST)}>
        I
      </TestAccess>
      <TestAccess
        active={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_1)}
        participant={participant} testId={SELECTED_TEST_1} testData={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_1)}
      >
        S1
      </TestAccess>
      <TestAccess
        active={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_2)}
        participant={participant} testId={SELECTED_TEST_2} testData={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_2)}
      >
        S2
      </TestAccess>
      <TestAccess
        active={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_3)}
        participant={participant} testId={SELECTED_TEST_3} testData={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_3)}
      >
        S3
      </TestAccess>
      <TestAccess
        active={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_4)}
        participant={participant} testId={SELECTED_TEST_4} testData={completedtests.find((t) => parseInt(t.id) === SELECTED_TEST_4)}
      >
        S4
      </TestAccess>
      <TestAccess
        active={completedtests.find((t) => parseInt(t.id) === CHALLENGE_TEST)}
        participant={participant} testId={CHALLENGE_TEST} testData={completedtests.find((t) => parseInt(t.id) === CHALLENGE_TEST)}
      >
        D
      </TestAccess>
    </Root>
  ) : null;
};

export default AccessTests;

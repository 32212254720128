import React from "react";
import styled from "styled-components";
import { Modal, Tooltip } from "@mui/material";
import TestReport from "./TestReport";

const Root = styled.button`
  font-weight: ${(props) => (props.active ? 600 : 300)};
  color: ${(props) =>
    props.active ? props.theme.palette.principal : props.theme.palette.details};
  user-select: ${(props) => (props.active ? "auto" : "none")};
  border: none;
  background: none;
  &:hover {
    cursor: ${(props) => (props.active ? "pointer" : "inherit")};
  }
`;

const TestAccess = ({ active, participant, testId, testData, children }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if(active){
      setOpen(true);
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Tooltip title={testData?.name}>
      <Root onClick={handleOpen} active={active}>{children}</Root>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <TestReport participant={participant} testId={testId} testData={testData} />
      </Modal>
    </>
  );
};

export default TestAccess;

import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SelectInput from "../inputs/SelectInput";
import { API_URL, API_URL_UM } from "../../config";
import axios from "axios";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import BasicEditor from "../textEditors/basicEditor";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const AssociatedMeet = styled.div`
  width: 100%;
  height: 100%;
`;

const NewUser = ({ userData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const { token } = useAuth();

  const loggedUser = decodeToken(token);

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [newUser, setNewUser] = React.useState(
    userData
      ? {
          firstName: userData.firstName,
          lastName: userData.lastName,
          userName: userData.userName,
          email: userData.email,
          role: null,
          associatedMeet: "",
          dni: "",
        }
      : {
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          password: "",
          repassword: "",
          role: null,
          associatedMeet: "",
          dni: "",
        }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setNewUser({
      ...newUser,
      [name]: value,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setNewUser({
      ...newUser,
      associatedMeet: dataeditor,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();

    if (userData) {
      const url = `${API_URL_UM}/api/users?id=${userData.id}`;
      axios
        .put(
          url,
          {
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            userName: newUser.userName,
            email: newUser.email,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          const backend_url = `${API_URL}/professionals/usermanagerid/${userData.id}`;
          let formdata = new FormData();
          formdata.append("firstname", newUser.firstName);
          formdata.append("lastname", newUser.lastName);
          formdata.append("userName", newUser.userName);
          formdata.append("email", newUser.email);
          formdata.append("associatedMeet", newUser.associatedMeet);
          formdata.append("dni", newUser.dni);
          formdata.append("updatedUser", loggedUser?.id);
          axios
            .put(backend_url, formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((profile) => {
              alertMessage(
                intl.formatMessage({ id: "user updated" }),
                alertConstants.INFO
              );
              handleClose();
            })
            .catch((err) => {
              console.log(err);
            });
          handleClose();
          alertMessage(
            intl.formatMessage({ id: "user updated" }),
            alertConstants.INFO
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (newUser.password === newUser.repassword) {
        const url = `${API_URL_UM}/api/users`;
        axios
          .post(
            url,
            {
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              userName: newUser.userName,
              email: newUser.email,
              password: newUser.password,
              role: newUser.role.value,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            let formdata = new FormData();
            formdata.append("userManagerId", response.data.id);
            formdata.append("firstname", newUser.firstName);
            formdata.append("lastname", newUser.lastName);
            formdata.append("userName", newUser.userName);
            formdata.append("email", newUser.email);
            formdata.append("associatedMeet", newUser.associatedMeet);
            formdata.append("dni", newUser.dni);
            formdata.append("createdUser", loggedUser?.id);
            const backend_url = `${API_URL}/professionals`;
            axios
              .post(backend_url, formdata, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((profile) => {
                alertMessage(
                  intl.formatMessage({ id: "user created" }),
                  alertConstants.INFO
                );
                handleClose();
              })
              .catch((err) => {
                console.log(err);
                alertMessage(err.response?.data?.msg, alertConstants.ERROR);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alertMessage(
          intl.formatMessage({ id: "check passwords" }),
          alertConstants.ERROR
        );
      }
    }
  };

  const getProfessionalData = () => {
    axios
      .get(`${API_URL}/professionals/usermanagerid/${userData.id}`)
      .then((res) => {
        const professional = res?.data;
        setNewUser({
          ...newUser,
          associatedMeet:
            professional && professional.associatedMeet
              ? professional.associatedMeet
              : "",
        });
      });
  };

  React.useEffect(() => {
    if (userData) {
      getProfessionalData();
    }
    setEditorLoaded(true);
  }, []);

  return (
    <Root>
      <Title>{userData ? translate("edit user") : translate("new user")}</Title>
      <TextField
        id="firstName"
        name="firstName"
        onChange={handleChange}
        value={newUser.firstName}
        label={intl.formatMessage({ id: "firstName" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        required
      />
      <TextField
        id="lastName"
        name="lastName"
        onChange={handleChange}
        value={newUser.lastName}
        label={intl.formatMessage({ id: "lastName" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        required
      />
      {!userData && (
        <TextField
          id="dni"
          name="dni"
          onChange={handleChange}
          value={newUser.dni}
          label={intl.formatMessage({ id: "dni" })}
          variant="outlined"
          fullWidth
          margin="dense"
          size="small"
          required
        />
      )}
      <TextField
        id="userName"
        name="userName"
        onChange={handleChange}
        value={newUser.userName}
        label={intl.formatMessage({ id: "userName" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        required
      />
      <TextField
        id="email"
        name="email"
        type="email"
        onChange={handleChange}
        value={newUser.email}
        label={intl.formatMessage({ id: "email" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        required
      />
      {!userData && (
        <TextField
          id="password"
          type="password"
          name="password"
          onChange={handleChange}
          value={newUser.password}
          label={intl.formatMessage({ id: "password" })}
          variant="outlined"
          fullWidth
          margin="dense"
          size="small"
          required
        />
      )}
      {!userData && (
        <TextField
          id="repassword"
          type="password"
          name="repassword"
          onChange={handleChange}
          value={newUser.repassword}
          label={intl.formatMessage({ id: "repassword" })}
          variant="outlined"
          fullWidth
          margin="dense"
          size="small"
          required
        />
      )}
      {!userData && (
        <SelectInput
          value={newUser.role}
          fieldlabel="name"
          fieldvalue="id"
          dataurl={`${API_URL_UM}/api/users/roles`}
          label={intl.formatMessage({ id: "role" })}
          name="role"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          withCredentials={true}
        />
      )}
      <AssociatedMeet>
        <BasicEditor
          name="associatedMeet"
          value={newUser.associatedMeet}
          onChange={handleEditor}
          editorLoaded={editorLoaded}
          maxContentHeight="300px"
          minContentHeight="250px"
          placeholder={intl.formatMessage({
            id: "insert meet data here...",
          })}
        />
      </AssociatedMeet>
      <Button
        bg={theme.palette.accent}
        color={theme.palette.base}
        onClick={handleAction}
      >
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default NewUser;

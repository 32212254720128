import styled from "styled-components";

const Root = styled.span`
width: fit-content;
height: ${(props) => (props.show ? "auto" : 0)};
overflow: hidden;
color: ${(props) => props.theme.palette.error};
padding: ${(props) => (props.show ? "8px 10px" : 0)};
opacity: ${(props) => (props.show ? 1 : 0)};
border: ${(props) => props.theme.palette.error};
background: ${(props) => `${props.theme.palette.error}10`};
border-radius: 8px;
margin-bottom: 10px;
font-size: 0.875rem;
transition: all 300ms ease-in-out;
`;

const InputError = ({show, children}) => {
    return (
        <Root show={show}>
            {children}
        </Root>
    );
}

export default InputError;
import { BarChartSales } from "./components/visualizations/barchart-sales";
import { Indicators } from "./components/visualizations/indicators/indicators";
import { ProductCategoryDonut } from "./components/visualizations/product-category-chart";
import { TopProductsChart } from "./components/visualizations/top-products-chart";
import styled from "styled-components";

const DashboardLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 1.25rem 0;
  max-width: 1440px;
  margin: 0 auto;
`;

const IndicatorPanel = styled.div`
  width: 100%;
  height: 18rem /* 288px */;
  grid-column: span 3 / span 3;
  @media (min-width: 480px) {
    height: 12rem /* 192px */;
  }
`;

const BarchartPanel = styled.div`
  width: 100%;
  height: 18rem /* 288px */;
  grid-column: span 3 / span 3;
  padding: 0 0.5rem;
`;

const BarchartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem /* 8px */;
  padding-top: 2.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const TitlePanel = styled.h2`
  position: absolute;
  top: 0.5rem /* 8px */;
  left: 0.5rem /* 8px */;
  font-weight: 600;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
`;

const TopChartPanel = styled.div`
  position: relative;
  width: 100%;
  height: 24rem /* 384px */;
  grid-column: span 3 / span 3;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  @media (min-width: 976px) {
    grid-column: span 2 / span 2;
  }
`;

const DonutChartPanel = styled.div`
  position: relative;
  width: 100%;
  height: 24rem /* 384px */;
  grid-column: span 3 / span 3;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  @media (min-width: 976px) {
    grid-column: span 1 / span 1;
  }
`;

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  border-radius: 0.5rem /* 8px */;
  padding-top: 2.75rem /* 44px */;
`;

function Dashboard() {
  return (
    <DashboardLayout>
      <IndicatorPanel>
        <Indicators />
      </IndicatorPanel>
      <BarchartPanel>
        <BarchartWrapper>
          <TitlePanel>Contactos por mes</TitlePanel>
          <BarChartSales />
        </BarchartWrapper>
      </BarchartPanel>
      <TopChartPanel>
        <ChartWrapper>
          <TitlePanel>Top 10 sedes más contactadas</TitlePanel>
          <TopProductsChart />
        </ChartWrapper>
      </TopChartPanel>
      <DonutChartPanel>
        <ChartWrapper>
          <TitlePanel>Distribución de contactos por nivel</TitlePanel>
          <ProductCategoryDonut />
        </ChartWrapper>
      </DonutChartPanel>
    </DashboardLayout>
  );
}

export default Dashboard;

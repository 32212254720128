import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 95%;
    height: auto;
    box-shadow: 0 0 2px ${props => props.active ? `${props.theme.palette.accent}` : 'rgba(0,0,0,0.5)'};
    border-radius: 8px;
    padding: 5px;
    margin: 5px 0;
`

const EventDate = styled.span`
    font-size: 1rem;
    font-weight: 600;
    color: ${props => props.theme.palette.principal};
`

const Description = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 0 0 5px;
    margin: 10px 0;
    font-style: 'italic';
    color: ${props => props.theme.palette.details};
`

const ParticipantData = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 0 0 10px;
`

const Participant = styled.span`
    width: 100%;
    height: 100%;
    padding: 0 0 0 5px;
    font-weight: 600;
    color: ${props => props.theme.palette.principal};
    margin: 5px 0 2px 0;
`

const Event = ({event}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    return (
        <Root active={event.assignedParticipant ? true : false}>
            <EventDate color={theme.palette.details}>
                <span>{`${intl.formatTime(event.start)} - ${intl.formatTime(event.end)}`}</span>
            </EventDate>
            {
                event.assignedProfessional ?
                <Participant>{translate('assigned professional')}</Participant>
                : null
            }
            {
                event.assignedProfessional ?
                <ParticipantData>{event.assignedProfessional.fullname}</ParticipantData>
                : null
            }
            {
                event.assignedParticipant ?
                <Participant>{translate('assigned participant')}</Participant>
                : null
            }
            {
                event.assignedParticipant ?
                <ParticipantData>{event.assignedParticipant.fullname}</ParticipantData>
                : null
            }
            {
                event.assignedParticipant && event.description ?
                <Description dangerouslySetInnerHTML={{__html: event.description}} />
                : null
            }
        </Root>
    );
}

export default Event;
import { LoadingLayout } from "./loading-layout";
import { LoadingText } from "./loading-text";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  to {
        transform: rotate(360deg);
    }
`;

const pulse = keyframes`
  50% {
      opacity: .5;
  }
`;

const DonutchartLoadingWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const DonutLoadingSkeleton = styled.div`
  width: 6rem /* 96px */;
  height: 6rem /* 96px */;
  border-width: 8px;
  border-style: dashed;
  border-radius: 9999px;
  border-color: aliceblue;
  animation: ${spin} 1s linear infinite;
`;

export const DonutchartLoading = () => {
  return (
    <LoadingLayout>
      <DonutchartLoadingWrapper>
        <DonutLoadingSkeleton></DonutLoadingSkeleton>
        <LoadingText />
      </DonutchartLoadingWrapper>
    </LoadingLayout>
  );
};

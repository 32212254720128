import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../providers/theme";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import translate from "../../providers/i18n/translate";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventsDay from "./EventsDay";
import Modal from "@mui/material/Modal";
import NewEvent from "./NewEvent";
import ViewEvent from "./ViewEvent";
import axios from "axios";
import { API_URL } from "../../config";
import { useIntl } from "react-intl";
import ShowDataEvent from "./ShowDataEvent";

require("moment/locale/es.js");

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 0.7fr 0.3fr;
  }
`;

const ContainerCalendar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContainerEvents = styled.div`
  position: relative;
  display: none;
  width: 100%;
  height: 500px;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const ContainerData = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  grid-column: 1/3;
`;

const compareDates = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

const BasicCalendar = (props) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [eventsList, setEventsList] = React.useState([]);
  const [eventsDay, setEventsDay] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [newStart, setNewStart] = React.useState(null);
  const [newEnd, setNewEnd] = React.useState(null);
  const [viewEvent, setViewEvent] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refresh();
    setOpen(false);
  };
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => {
    refresh();
    setOpenView(false);
  };

  const onEventResize = (data) => {
    const newEvent = eventsList.filter((e) => e.id === data.event.id)[0];
    var formdata = new FormData();
    formdata.append("description", newEvent.description);
    formdata.append("meet", newEvent.meet);
    formdata.append("startDate", data.start);
    formdata.append("endDate", data.end);
    formdata.append("taskType", newEvent.taskType);
    formdata.append("status", newEvent.status);
    if (newEvent.assignedProfessional) {
      formdata.append("assignedProfessional", newEvent.assignedProfessional.id);
    }

    if (newEvent.assignedParticipant) {
      formdata.append("assignedParticipant", newEvent.assignedParticipant.id);
    }
    if (newEvent.assignedCenter) {
      formdata.append("assignedCenter", newEvent.assignedCenter.id);
    }
    formdata.append("updatedUser", 1);
    const url = `${API_URL}/tasks/${newEvent.id}`;
    axios
      .put(url, formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onEventDrop = (data) => {
    const newEvent = eventsList.filter((e) => e.id === data.event.id)[0];
    var formdata = new FormData();
    formdata.append("description", newEvent.description);
    formdata.append("meet", newEvent.meet);
    formdata.append("startDate", data.start);
    formdata.append("endDate", data.end);
    formdata.append("taskType", newEvent.taskType);
    formdata.append("status", newEvent.status);
    if (newEvent.assignedProfessional) {
      formdata.append("assignedProfessional", newEvent.assignedProfessional.id);
    }

    if (newEvent.assignedParticipant) {
      formdata.append("assignedParticipant", newEvent.assignedParticipant.id);
    }
    if (newEvent.assignedCenter) {
      formdata.append("assignedCenter", newEvent.assignedCenter.id);
    }
    formdata.append("updatedUser", 1);
    const url = `${API_URL}/tasks/${newEvent.id}`;
    axios
      .put(url, formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleDoubleClickEvent = (event) => {
    setViewEvent(event);
    handleOpenView();
  };

  const handleSelect = ({ start, end }) => {
    setNewStart((newStart) => start);
    setNewEnd((newEnd) => end);
    handleOpen();
  };

  const refresh = () => {
    const today = new Date();
    axios
      .get(`${API_URL}/tasks`)
      .then((tasks) => {
        const eventsResult = tasks.data.rows.map((task) => {
          return {
            id: task.id,
            description: task.description,
            isAllDay: false,
            start: new Date(task.startDate),
            end: new Date(task.endDate),
            taskType: task.taskType,
            status: task.status,
            assignedCenter: task.assignedCenter,
            assignedProfessional: task.assignedProfessional,
            assignedParticipant: task.assignedParticipant,
            meet: task.meet
          };
        });
        setEventsList(eventsResult);
        setEventsDay(eventsResult.filter((e) => compareDates(e.start, today)));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    refresh();
  }, []);

  return (
    eventsList && (
      <Root>
        <ContainerCalendar>
          <DnDCalendar
            selectable
            defaultDate={moment().toDate()}
            localizer={localizer}
            events={eventsList}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            culture="es"
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            resizable
            onSelectEvent={handleClickEvent}
            onSelectSlot={handleSelect}
            onDoubleClickEvent={handleDoubleClickEvent}
            step={15}
            min={new Date(0, 0, 0, 7, 0, 0)}
            max={new Date(0, 0, 0, 22, 0, 0)}
            messages={{
              next: translate("next"),
              previous: translate("previous"),
              today: translate("today"),
              month: translate("month"),
              week: translate("week"),
              day: translate("day"),
            }}
            style={{ height: 500 }}
          />
          <Modal open={open} onClose={handleClose}>
            <NewEvent start={newStart} end={newEnd} handleClose={handleClose} />
          </Modal>
          <Modal open={openView} onClose={handleCloseView}>
            <NewEvent eventData={viewEvent} handleClose={handleCloseView} />
          </Modal>
        </ContainerCalendar>
        <ContainerEvents>
          <EventsDay eventsDay={eventsDay} />
        </ContainerEvents>
        <ContainerData>
          {
            selectedEvent ?
            <ShowDataEvent selectedEvent={selectedEvent} />
            : null
          }
        </ContainerData>
      </Root>
    )
  );
};

export default BasicCalendar;

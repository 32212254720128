import CoursesOffered from "../templatesSections/coursesOffered";

export const sectionTypeOptions = [
    {
        id: "pageLayout",
        name: "Encabezado Layout"
    },
    {
        id: "introPage",
        name: "Introducción de página"
    },
    {
      id: "homeResume",
      name: "Resumen de inicio",
    },
    {
      id: "textSection",
      name: "Texto libre",
    },
    {
      id: "cardsBand",
      name: "Banda de tarjetas"
    },
    {
      id: "threeButtons",
      name: "Tres botones"
    },
    {
        id: "fourButtons",
        name: "Cuatro botones"
    },
    {
        id: "frequentQuestions",
        name: "Preguntas frecuentes"
    },
    {
        id: "notificationByImage",
        name: "Notificación por imagen"
    },
    {
        id: "notificationByText",
        name: "Notificación por texto"
    },
    {
        id: "pageInConstruction",
        name: "Página en construcción"
    },
    {
        id: "longTwoColumnsImage",
        name: "Dos columnas con imagen larga"
    },
    {
        id: "lightBoxGallery",
        name: "Galería lightbox"
    },
    {
        id: "coursesOffered",
        name: "Cursos ofrecidos"
    },
    {
        id: "cardsList",
        name: "Lista de tarjetas"
    },
    {
        id: "twoColumnsImage",
        name: "Dos columnas con imagen"
    },
    {
        id: "ampMap",
        name: "Mapa"
    }
  ];
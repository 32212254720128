import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import axios from "axios";
import { API_URL_LUTECE } from "../../config";
import SelectInput from "../inputs/SelectInput";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.accent};
  border: none;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
  cursor: pointer;
`;

const AssignCourse = ({ assignedCourses, selectedLevel, handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const { token } = useAuth();
  const userData = decodeToken(token);

  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [courseList, setCourseList] = React.useState(null);

  const getCourseList = () => {
    const assignedCoursesList = assignedCourses.map(ac => parseInt(ac.idLutece));
    axios
      .get(`${API_URL_LUTECE}/courses/lutece/level/${selectedLevel.idLutece}`)
      .then((result) => {
        setCourseList(result.data.filter(d => !assignedCoursesList.includes(parseInt(d.idCursada))).map(r => {
          return {
            level: parseInt(selectedLevel.id),
            idLutece: r.idCursada,
            courseCode: r.codigoCurso.trim(),
            idHeadquarter: r.idSede,
            headquarter: r.sede,
            idTurn: r.idTurno,
            turn: r.turno.trim(),
            idFrequency: r.idFrecuencia,
            frequency: r.frecuencia,
            classroom: r.aula.trim(),
            initialDate: r.fechaInicial,
            endDate: r.fechaFinal,
            days: r.dias.trim(),
            quota: parseInt(r.cupo.trim()),
            enrolled: r.inscriptos,
            rename: `${r.nivel.trim()} ${r.numNivel}`,
            name: `${r.nivel.trim()} ${r.numNivel} (${r.codigoCurso.trim()}) - ${r.turno.trim()} - ${r.sede.trim()}`,
            value: r.idCursada,
          }
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAutocomplete = (name, value) => {
    setSelectedCourse(value);
  };

  const handleSubmit = () => {
    const newCourse = courseList.find(c => c.value == selectedCourse.value);
    if(selectedLevel){
      const formdata = {
        ...newCourse,
        createdUser: userData?.id,
      };
      axios
        .post(`${API_URL_LUTECE}/courses`, formdata, {})
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "course assigned" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            handleClose();
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  React.useEffect(() => {
    getCourseList();
  }, []);

  return (
    <Root>
      {courseList ? (
        <SelectInput
          value={selectedCourse}
          fieldlabel="name"
          fieldvalue="value"
          data={courseList}
          label={intl.formatMessage({ id: "courses" })}
          name="selectedCourse"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          required
        />
      ) : (
        <span>Cargando...</span>
      )}

      <StyledButton onClick={handleSubmit}>{translate("add")}</StyledButton>
    </Root>
  );
};

export default AssignCourse;

import styled from "styled-components";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';

const Root = styled.div`
    margin: 10px 0;
`

const Text = styled.p`
  font-weight: 600;
`;

const Option = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  margin: 5px 0;
  color: ${props => props.correct ? "green" : props.validate ? "red" : "black"};
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &:hover {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
`;

const Icon = styled.div`
    color: ${props => props.iconcolor ? props.iconcolor : "inherit"};
    margin-left: 10px;
`

const Question = ({data}) => {

    return (
        <Root>
            <Text>{data.question.question}</Text>
            {data.question.answers && data.question.answers.length > 0
          ? data.question.answers.map((answer, index) => (
              <Option key={`answer_${index}_${answer.id}`} validate={!answer.correct && JSON.parse(data.answers[0]).id == answer.id} correct={answer.correct === true || answer.correct === "true" ? true : false}>
                <input
                  type="radio"
                  id={`answer_${answer.text}`}
                  name={data.question.id}
                  value={JSON.stringify(answer)}
                  required
                  checked={
                    data.answers
                      ? JSON.parse(data.answers[0]).id == answer.id
                      : undefined
                  }
                />
                <label htmlFor={`answer_${answer.text}`}>{answer.text} {JSON.parse(data.answers[0]).id == answer.id ? answer.correct ? <Icon iconcolor="green"><CheckBoxIcon /></Icon> : <Icon iconcolor="red"><DangerousIcon /></Icon> : null}</label>
              </Option>
            ))
          : null}
        </Root>
    );
}

export default Question;
import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useState } from "react";
import { Sector } from "recharts";
import { DonutchartLoading } from "../../../loadings/donutchart-loading";
import { useService } from "../../../../hooks/useService";
import { getProductCategory } from "../../services/get-product-category";

const colors = [
  "#336699",
  "#4D90C7",
  "#005B96",
  "#99BADD",
  "#6699CC",
  "#1F456E",
  "#2E3B4E",
  "#80AEDC",
  "#003366",
  "#7395AE",
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    name,
    value,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * (midAngle ?? 0));
  const cos = Math.cos(-RADIAN * (midAngle ?? 0));
  const sx = (cx ?? 0) + ((outerRadius ?? 0) + 10) * cos;
  const sy = (cy ?? 0) + ((outerRadius ?? 0) + 10) * sin;
  const mx =
    (cx ?? 0) +
    ((outerRadius ?? 0) + (sin >= 0.5 || sin <= -0.5 ? 25 : 15)) * cos;
  const my =
    (cy ?? 0) +
    ((outerRadius ?? 0) + (sin >= 0.5 || sin <= -0.5 ? 25 : 15)) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 11;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize={25}>
        {`${((percent ?? 0) * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={(outerRadius ?? 0) + 4}
        outerRadius={(outerRadius ?? 0) + 8}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {value}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 8}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {name}
      </text>
    </g>
  );
};

export const ProductCategoryDonut = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    data: productCategory,
    loading,
    error,
  } = useService(getProductCategory, undefined);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  if (loading) {
    return <DonutchartLoading />;
  }

  return !error ? (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={productCategory}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={100}
          fill="#3d8bfd"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {productCategory
            ? productCategory.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))
            : null}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  ) : null;
};

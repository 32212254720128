import React from "react";
import styled from "styled-components";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Table, SearchInput } from "../components/tables/table";
import axios from "axios";
import { API_URL, FRONTEND_URL } from "../config";
import AddIcon from "@mui/icons-material/Add";
import { formatDate } from "../utils/dateUtils";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 50px;
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "red")};
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  padding: 25px 0 0 0;
`;

const NewIcon = styled.div`
  position: absolute;
  right: 20px;
  width: 50px;
  height: 50px;
  top: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.bg ? props.bg : "7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1);
  }
  z-index: 99;
  @media (min-width: 1024px) {
    right: 50px;
    bottom: 50px;
  }
`;

export default function PostsPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const navigate = useNavigate();

  const intl = useIntl();

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const [search, setSearch] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    tableRef.current.clean();
    setOpen(false);
  };
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    tableRef.current.clean();
    setOpenEdit(false);
  };

  const [editData, setEditData] = React.useState(null);

  const columnsTable = [
    { title: "ID", field: "id", width: "8%", order: false, hidden: false },
    {
      title: translate("start"),
      field: "startDate",
      order: false,
      width: "10%",
      hidden: false,
      render: (d) => formatDate(d.startDate),
    },
    {
      title: translate("end"),
      field: "endDate",
      order: false,
      width: "10%",
      hidden: false,
      render: (d) => formatDate(d.endDate),
    },
    {
      title: translate("title"),
      field: "title",
      width: "30%",
      order: false,
      hidden: false,
    },
    {
      title: translate("category"),
      field: "category.name",
      width: "12%",
      order: false,
      hidden: false,
      render: (d) => d.category.name,
    },
    {
      title: translate("slug"),
      field: "slug",
      width: "30%",
      order: false,
      hidden: false,
      render: (d) => (
        <a
          href={`${FRONTEND_URL}/cultura/${d.category.slug}/post/${d.slug}`}
          target="_blank"
        >
          {d.slug}
        </a>
      ),
    },
    {
      title: translate("actions"),
      actions: true,
      width: "auto",
      hidden: false,
    },
  ];

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const handleNewItem = (data) => {
    navigate(`/admin/newpost`);
  };

  const handleEditItem = (data) => {
    navigate(`/admin/posts/${data.id}`);
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL}/events/${data.id}`)
        .then((result) => {
          setTimeout(() => {
            tableRef.current.clean();
            alertMessage(
              intl.formatMessage({ id: "post deleted" }),
              alertConstants.INFO
            );
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const actionsTable = {
    edit: {
      icon: theme.icons.edit,
      tooltip: "Editar",
      onClick: handleEditItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
    delete: {
      icon: theme.icons.delete,
      tooltip: "Eliminar",
      onClick: handleDeleteItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
  };

  return (
    <Root>
      <Title color={theme.palette.accent} borderColor={theme.palette.details}>
        {translate("posts")}
      </Title>
      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          disablePlain
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_URL}/events`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                  },
                })
                .then((result) => {
                  resolve(result.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        >
          <SearchInput
            search={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
        </Table>
        <NewIcon color={theme.palette.base} bg={theme.palette.accent} onClick={handleNewItem}><AddIcon /></NewIcon>
      </Content>
    </Root>
  );
}

import React from "react";
import styled from "styled-components";
import { API_URL_LUTECE } from "../config";
import axios from "axios";
import translate from "../providers/i18n/translate";
import LuteceTarget from "../components/targets/LuteceTarget";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";
import { useAuth } from "../providers/authentication";
import { decodeToken } from "../hooks/jwt";
import SelectedTarget from "../components/targets/SelectedTarget";
import TargetToSelect from "../components/targets/TargetToSelect";
import { Modal } from "@mui/material";
import AssignLevel from "../components/levels/AssignLevel";
import AssignedLevel from "../components/levels/AssignedLevel";
import AssignGroup from "../components/groups/AssignGroup";
import AssignedGroup from "../components/groups/AssignedGroup";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
`;

const TargetsContainer = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px 0;
`;

const LevelsContainer = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const Title = styled.h1`
  grid-column: 1/3;
`;

const Text = styled.p`
  grid-column: 1/3;
`;

const Subtitle = styled.h2`
  margin-bottom: 10px;
  border-bottom: 1px solid black;
`;

const AssignLevelButton = styled.button`
  width: fit-content;
  padding: 8px 16px;
  border: none;
  color: white;
  background: #006611;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin: 10px 0;
`

export default function TargetsPage() {
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const intl = useIntl();
  const { token } = useAuth();
  const userData = decodeToken(token);

  const [open, setOpen] = React.useState(false);
  const [openGroup, setOpenGroup] = React.useState(false);
  const [luteceTargets, setLuteceTargets] = React.useState([]);
  const [selectedTargets, setSelectedTargets] = React.useState([]);
  const [assignedLevels, setAssignedLevels] = React.useState([]);
  const [assignedGroups, setAssignedGroups] = React.useState([]);
  const [selectedTarget, setSelectedTarget] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleOpenGroup = () => setOpenGroup(true);
  const handleClose = () => {
    setOpen(false);
    if(selectedTarget){
      getAssignedLevels(selectedTarget.id);
    }
  };

  const handleCloseGroup = () => {
    setOpenGroup(false);
    if(selectedTarget){
      getAssignedGroups(selectedTarget.id);
    }
  };

  const getLuceteTargets = () => {
    axios
      .get(`${API_URL_LUTECE}/lutecetargets`)
      .then((result) => {
        setLuteceTargets(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSelectedTargets = () => {
    axios
      .get(`${API_URL_LUTECE}/targets`)
      .then((result) => {
        setSelectedTargets(result.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssignedLevels = (id) => {
    axios
      .get(`${API_URL_LUTECE}/assignedlevels/${id}`)
      .then((result) => {
        setAssignedLevels(result.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssignedGroups = (id) => {
    axios
      .get(`${API_URL_LUTECE}/assignedgroups/${id}`)
      .then((result) => {
        setAssignedGroups(result.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddTarget = (data) => {
    const description = data.description.trim();
    const formdata = {
      idCourseType: data.idCourseType,
      name: description,
      mnemonic: data.mnemonic,
      numberLevel: data.numberLevel,
      description: description,
      content: description,
      rename: description,
      createdUser: userData?.id,
    };
    axios
      .post(`${API_URL_LUTECE}/targets`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "target assigned" }),
          alertConstants.INFO
        );
        setTimeout(() => {
          getSelectedTargets();
        }, 500);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const onRenameTarget = (id, newname) => {
    const formdata = {
      rename: newname,
      updatedUser: userData?.id,
    };
    axios
      .put(`${API_URL_LUTECE}/targets/rename/${id}`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "target updated" }),
          alertConstants.INFO
        );
        setTimeout(() => {
          getSelectedTargets();
        }, 500);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const onRenameLevel = (id, newname) => {
    const formdata = {
      rename: newname,
      updatedUser: userData?.id,
    };
    axios
      .put(`${API_URL_LUTECE}/levels/rename/${id}`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "level updated" }),
          alertConstants.INFO
        );
        setTimeout(() => {
          if(selectedTarget){
            getAssignedLevels(selectedTarget.id);
          }
        }, 500);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const onRenameGroup = (id, newname) => {
    const formdata = {
      rename: newname,
      updatedUser: userData?.id,
    };
    axios
      .put(`${API_URL_LUTECE}/groups/rename/${id}`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "group updated" }),
          alertConstants.INFO
        );
        setTimeout(() => {
          if(selectedTarget){
            getAssignedGroups(selectedTarget.id);
          }
        }, 500);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const onRedescriptionLevel = (id, newdescription) => {
    const formdata = {
      redescription: newdescription,
      updatedUser: userData?.id,
    };
    axios
      .put(`${API_URL_LUTECE}/levels/redescription/${id}`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "level updated" }),
          alertConstants.INFO
        );
        setTimeout(() => {
          if(selectedTarget){
            getAssignedLevels(selectedTarget.id);
          }
        }, 500);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const onRedescriptionGroup = (id, newdescription) => {
    const formdata = {
      redescription: newdescription,
      updatedUser: userData?.id,
    };
    axios
      .put(`${API_URL_LUTECE}/groups/redescription/${id}`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "group updated" }),
          alertConstants.INFO
        );
        setTimeout(() => {
          if(selectedTarget){
            getAssignedGroups(selectedTarget.id);
          }
        }, 500);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const removeTarget = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL_LUTECE}/targets/${id}`)
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "target removed" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            getSelectedTargets();
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const removeLevel = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL_LUTECE}/levels/${id}`)
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "level removed" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            if(selectedTarget){
              getAssignedLevels(selectedTarget.id);
            }
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  }

  const removeGroup = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL_LUTECE}/groups/${id}`)
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "group removed" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            if(selectedTarget){
              getAssignedGroups(selectedTarget.id);
            }
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  }

  const selectTarget = (id) => {
    const target = selectedTargets.find((s) => s.id === id);
    setSelectedTarget(target);
    if(selectedTarget){
      getAssignedLevels(target.id);
      getAssignedGroups(target.id);
    }
  };

  React.useEffect(() => {
    getLuceteTargets();
  }, []);

  React.useEffect(() => {
    getSelectedTargets();
  }, []);

  return (
    <Root>
      <Title>{translate("targets")}</Title>
      <Text>
        {translate("Select the target audience visible on the page.")}
      </Text>
      <TargetsContainer>
        <Box>
          <Subtitle>{translate("selected targets")}</Subtitle>
          {selectedTargets &&
            selectedTargets.map((st, index) => (
              <SelectedTarget
                key={`selected_target_${index}`}
                data={st}
                onDelete={removeTarget}
                onRename={onRenameTarget}
              />
            ))}
        </Box>
        <Box>
          <Subtitle>{translate("lutece targets")}</Subtitle>
          {luteceTargets &&
            selectedTargets &&
            luteceTargets
              .filter(
                (lutece) =>
                  !selectedTargets
                    .map((sel) => parseInt(sel.idCourseType))
                    .includes(parseInt(lutece.idCourseType))
              )
              .map((lt, index) => (
                <LuteceTarget
                  key={`lutece_target_${index}_${lt.idCourseType}`}
                  data={lt}
                  onSelect={AddTarget}
                />
              ))}
        </Box>
      </TargetsContainer>
      <Title>{translate("level assignment")}</Title>
      <Text>
        {translate(
          "Select the target audience and then assign the corresponding levels."
        )}
      </Text>
      <LevelsContainer>
        <Box>
          <Subtitle>{translate("targets")}</Subtitle>
          {selectedTargets &&
            selectedTargets.map((st, index) => (
              <TargetToSelect
                key={`selected_target_${index}`}
                data={st}
                onSelect={selectTarget}
                selected={selectedTarget}
              />
            ))}
        </Box>
        <Box>
          <Subtitle>
            {selectedTarget
              ? `${intl.formatMessage({ id: "level list in" })} ${
                  selectedTarget.rename ?? selectedTarget.name
                }`
              : translate("level list")}{" "}
          </Subtitle>
          {selectedTarget ? (
            assignedLevels.length === 0 ? (
              <p>
                {translate(
                  "The selected target audience has no assigned levels."
                )}
              </p>
            ) : (
              assignedLevels.map((al, index) => 
                <AssignedLevel key={`assigned_level_${index}`} data={al} onDelete={removeLevel} onRename={onRenameLevel} onRedescription={onRedescriptionLevel} selectedTarget={selectedTarget} getAssignedGroups={getAssignedGroups} />
              )
            )
          ) : (
            <p>{translate("You must select a target.")}</p>
          )}
          <AssignLevelButton onClick={handleOpen}>{translate('assign new level')}</AssignLevelButton>
        </Box>
        <Box>
          <Subtitle>
            {selectedTarget
              ? `${intl.formatMessage({ id: "groups list in" })} ${
                  selectedTarget.rename ?? selectedTarget.name
                }`
              : translate("groups list")}{" "}
          </Subtitle>
          {selectedTarget ? (
            assignedGroups.length === 0 ? (
              <p>
                {translate(
                  "The selected target audience has no assigned groups."
                )}
              </p>
            ) : (
              assignedGroups.map((al, index) => 
                <AssignedGroup key={`assigned_group_${index}`} data={al} onDelete={removeGroup} onRename={onRenameGroup} onRedescription={onRedescriptionGroup} />
              )
            )
          ) : (
            <p>{translate("You must select a target.")}</p>
          )}
          <AssignLevelButton onClick={handleOpenGroup}>{translate('assign new group')}</AssignLevelButton>
        </Box>
        <Modal open={open} onClose={handleClose}>
          <AssignLevel selectedTarget={selectedTarget} assignedLevels={assignedLevels} handleClose={handleClose} />
        </Modal>
        <Modal open={openGroup} onClose={handleCloseGroup}>
          <AssignGroup selectedTarget={selectedTarget} handleClose={handleCloseGroup} />
        </Modal>
      </LevelsContainer>
    </Root>
  );
}

export async function getAnualSales() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(salesData);
    }, 3000);
  });
}

const salesData = [
  { month: "Enero", contacts: 150 },
  { month: "Febrero", contacts: 200 },
  { month: "Marzo", contacts: 180 },
  { month: "Abril", contacts: 250 },
  { month: "Mayo", contacts: 300 },
  { month: "Junio", contacts: 280 },
  { month: "Julio", contacts: 320 },
  { month: "Agosto", contacts: 400 },
  { month: "Septiembre", contacts: 350 },
  { month: "Octubre", contacts: 300 },
  { month: "Noviembre", contacts: 280 },
  { month: "Diciembre", contacts: 320 },
];

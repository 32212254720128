import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]: {
    hello: "Hola, {name}!",
    next: "Siguiente",
    previous: "Previo",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    "assigned professional": "Profesional asignado",
    "assigned participant": "Participante asignado",
    "assigned center": "Sede asignada",
    "new event": "Nuevo evento",
    "edit event": "Editar evento",
    start: "Inicio",
    end: "Fin",
    eventsDay: "Eventos del día",
    reservations: "Reservas",
    hour: "Hora",
    center: "Centro",
    firstname: "Nombres",
    lastname: "Apellidos",
    email: "Correo",
    phone: "Tel.",
    tests: "Tests",
    status: "Estado",
    actions: "Acciones",
    page: "Página",
    of: "de",
    show: "Mostrar",
    records: "filas",
    requested: "Solicitada",
    free: "Libre",
    assigned: "Asignada",
    completed: "Completada",
    calendar: "Calendario",
    print: "Imprimir",
    title: "Título",
    category: "Categoría",
    view: "Ver",
    posts: "Eventos",
    post: "Evento",
    slug: "URL",
    "confirm delete": "¿Confirma la eliminación?",
    "event deleted": "Evento eliminado",
    "post deleted": "Evento eliminado",
    "edit post": "Editar evento",
    "new post": "Nuevo evento",
    apply: "Aplicar",
    cancel: "Cancelar",
    "title must not be empty": "Título no debe ser vacío",
    content: "Contenido",
    "post content": "Contenido del evento",
    "insert content here...": "Inserte el contenido aquí...",
    startDate: "Fecha inicio",
    endDate: "Fecha fin",
    schedule: "Horario",
    place: "Lugar",
    address: "Dirección",
    contact: "Contacto",
    price: "Precio",
    tag: "Etiqueta",
    change: "Cambiar",
    "choose file": "Elegir archivo",
    attachment: "Adjunto",
    "file not selected": "Archivo no seleccionado",
    remove: "Remover",
    postTitle: "Post Título",
    preTitle: "Pre Título",
    "category must not be empty": "Categoría no debe ser vacío",
    "startDate must not be empty": "Fecha Inicio no debe ser vacío",
    "endDate must not be empty": "Fecha fin no debe ser vacío",
    "schedule must not be empty": "Horario no debe ser vacío",
    "tag must not be empty": "Etiqueta no debe ser vacío",
    "principal image must not be empty":
      "La imagen principal no debe ser vacío",
    "principal image": "Imagen principal",
    "event created": "Evento creado",
    "event updated": "Evento actualizado",
    "user created": "Usuario creado",
    "user updated": "Usuario actualizado",
    users: "Usuarios",
    search: "Buscar",
    "thumbnail image": "Imagen miniatura",
    fullname: "Nombre completo",
    "no data": "Sin datos",
    "link to meet": "Enlace a videoconferencia",
    "assigned professional must not be empty":
      "El profesional asignado no puede ser vacío",
    "assigned center must not be empty": "La sede asignada no puede ser vacía",
    "level tests": "Tests de nivel",
    total: "Total",
    exit: "Salir",
    dashboard: "Dashboard",
    "insert meet data here...": "Insertar datos de videoconferencia aquí...",
    "status must not be empty": "El Estado no puede ser vacío",
    published: "Publicado",
    draft: "Borrador",
    outstanding: "Destacado",
    resume: "Resumen",
    "insert a summary of the event": "Inserte un resumen del evento",
    "limit summary to 250 characters": "Limite el resumen a 250 caracteres",
    "signature creator": "Creador de firmas",
    position: "Posición",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    youtube: "Youtube",
    logo: "Logo",
    download: "Descargar",
    "copy code": "Copiar código",
    save: "Guardar",
    "save as": "Guardar como...",
    "signature created": "Firma creada",
    "signature updated": "Firma actualizada",
    name: "Nombre",
    "signature deleted": "Firma eliminada",
    linkedin: "Linkedin",
    "targets page": "Público objetivo",
    targets: "Público objetivo",
    "lutece targets": "Público objetivo disponible en LUTECE",
    "selected targets": "Público objetivo seleccionado",
    "target assigned": "Público objetivo asignado",
    "target removed": "Público objetivo removido",
    "Select the target audience visible on the page.":
      "Seleccione el público objetivo visible en la página.",
    "level assignment": "Asignación de niveles",
    "Select the target audience and then assign the corresponding levels.":
      "Seleccione el público objetivo y luego asigne los niveles correspondientes.",
    "level list": "Lista de niveles disponibles",
    "level list in": "Lista de niveles disponibles en",
    "The selected target audience has no assigned levels.":
      "El público objetivo seleccionado no tiene niveles asignados.",
    "You must select a target.": "Debe seleccionar un público objetivo.",
    "assign new level": "Asignar nuevo nivel",
    levels: "Niveles",
    add: "Agregar",
    "level assigned": "Nivel asignado",
    "level removed": "Nivel removido",
    "courses page": "Cursos",
    "courses list": "Lista de cursos",
    "courses list in": "Lista de cursos en",
    "assign new course": "Asignar nuevo curso",
    courses: "Cursos",
    "course assigned": "Curso asignado",
    "course removed": "Curso removido",
    code: "Código",
    headquarter: "Sede",
    turn: "Turno",
    frequency: "Frecuencia",
    classroom: "Aula",
    initialDate: "Fecha inicio",
    quota: "Cupo",
    enrolled: "Inscriptos",
    "level updated": "Nivel actualizado",
    "target updated": "Público objetivo actualizado",
    "new name": "Nuevo nombre",
    description: "Descripcion",
    "groups list in": "Lista de grupos en",
    "groups list": "Lista de grupos",
    "The selected target audience has no assigned groups.":
      "El público objetivo seleccionado no tiene grupos asignados.",
    "assign new group": "Asignar nuevo grupo",
    "without group": "Sin grupo",
    "assign group": "Asignar grupo",
    pages: "Páginas",
    "pages page": "Páginas",
    "path must not be empty": "El path no debe ser vacío",
    "invalid slug": "URL inválido",
    "name must not be empty": "Nombre no debe ser vacío",
    path: "Camino",
    type: "Tipo",
    "section created": "Sección creada",
    "section edited": "Sección editada",
    "config section": "Configurar sección",
    homeResume: "Resumen del Home",
    textSection: "Sección de texto libre",
    "insert text here...": "Insertar texto aquí...",
    "button text": "Texto del botón",
    link: "Enlace",
    gallery: "Galería",
    "edit content": "Editar contenido",
    "add content": "Agregar contenido",
    slides: "Slides",
    pretitle: "Pre título",
    posttitle: "Post título",
    "section saved": "Sección guardada",
    "link must not be empty": "Enlace no debe ser vacío",
    "section type": "Tipo de sección",
    order: "Orden",
    text: "Texto",
    button: "Botón",
    action: "Acción",
    subtitle: "Subtítulo",
    question: "Pregunta",
    "insert response here...": "Inserte respuesta aquí...",
    "questions list": "Lista de preguntas",
    "add question": "Agregar pregunta",
    "question editor": "Editor de preguntas",
    frequentQuestions: "Preguntas frecuentes",
    "save question": "Guardar pregunta",
    "alter text must not be empty": "Texto alternativo no debe ser vacío",
    "alter text": "Texto alternativo",
    "notification image": "Imagen de la notificación",
    message: "Mensaje",
    image: "Imagen",
    "invert columns": "Invertir columnas",
    "back to pages": "Volver a páginas",
    Sections: "Secciones",
    "new section": "Nueva sección",
    lightBoxGallery: "Galería de imágenes lightbox",
    "no configuration": "Esta sección no necesita configuración",
    coursesOffered: "Cursos ofrecidos",
    cards: "Tarjetas",
    cardlist: "Lista de tarjetas",
    src: "Origen del mapa",
    audiences: "Audiencias",
    origin: "Origen",
    createdAt: "Creado",
    export: "Exportar",
    contactDetailTitle: "Detalle del contacto",
    mailto: "Dirigido al correo",
    lastName: "Apellido",
    idLutece: "ID de curso Lutece",
    level: "Nivel",
    contacts: "Contactos",
  },
};

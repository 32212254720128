import styled from "styled-components";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    width: fit-content;
    padding: 4px 8px;
    margin: 5px 0;
`
const Name = styled.span`
    line-height: 2rem;
    margin-right: 10px;
`

const StyledButton = styled.button`
    border: none;
    background: none;
    svg {
        color: red;
        cursor: pointer;
    }
`

const AssignedCourse = ({data, onDelete}) => {

    const onDeleteTarget = (e) => {
        onDelete(data.id);
    }

    return (
        <Root >
            <Name>{`${data.rename} (${data.courseCode})`}</Name>
            <StyledButton onClick={onDeleteTarget}><RemoveCircleIcon /></StyledButton>
        </Root>
    );
}

export default AssignedCourse;
import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import translate from "../../providers/i18n/translate";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Message = styled.p`

`

const CoursesOffered = React.forwardRef(({ id, userId }, ref) => {
    const intl = useIntl();
    let navigate = useNavigate();

    const submit = () => {
        navigate(-1);
    };

    React.useImperativeHandle(ref, () => ({
        handleAction() {
            submit();
        },
    }));

    return (
        <Root>
            <Message>{translate('no configuration')}</Message>
        </Root>
    );
});

export default CoursesOffered;

import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LabelList,
} from "recharts";
import { useService } from "../../../../hooks/useService";
import { getTopSalesProduct } from "../../services/get-top-sales-product";
import { HorizontalBarchartLoading } from "../../../loadings/horizontal-barchart-loading";

export const TopProductsChart = () => {
  const params = { limit: 10 };

  const {
    data: topProducts,
    loading,
    error,
  } = useService(getTopSalesProduct, params);

  if (loading) {
    return <HorizontalBarchartLoading />;
  }

  return !error ? (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={topProducts}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis type="number" />
        <YAxis type="category" dataKey="product" hide />
        <Tooltip />
        <Legend />
        <Bar dataKey="sales" fill="#336699">
          <LabelList dataKey="product" position="insideLeft" fill="white" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  ) : null;
};

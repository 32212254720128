import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    hello: "Hi, {name}!",
    next: "Next",
    previous: "Previous",
    today: "Today",
    month: "Month",
    week: "Week",
    day: "Day",
    "assigned professional": "Assigned professional",
    "assigned participant": "Assigned participant",
    "assigned center": "Assigned center",
    "new event": "New event",
    "edit event": "Edit event",
    start: "Start",
    end: "end",
    eventsDay: "Events Day",
    reservations: "Reservations",
    hour: "Hour",
    center: "Center",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    phone: "Phone",
    tests: "Tests",
    status: "Status",
    actions: "Actions",
    page: "Page",
    of: "of",
    show: "Show",
    records: "records",
    requested: "Requested",
    free: "Free",
    assigned: "Assigned",
    completed: "Completed",
    calendar: "Calendar",
    print: "Print",
    title: "Title",
    category: "Category",
    view: "View",
    posts: "Posts",
    post: "Post",
    slug: "URL",
    "confirm delete": "Confirm delete?",
    "event deleted": "Event deleted",
    "post deleted": "Event deleted",
    "edit post": "Edit post",
    "new post": "New post",
    apply: "Apply",
    cancel: "Cancel",
    "title must not be empty": "Title must not be empty",
    content: "Content",
    "post content": "Post content",
    "insert content here...": "Insert content here...",
    startDate: "Start Date",
    endDate: "End Date",
    schedule: "Schedule",
    place: "Place",
    address: "Address",
    contact: "Contact",
    price: "Price",
    tag: "Tag",
    change: "Change",
    "choose file": "Choose file",
    attachment: "Attachment",
    "file not selected": "File not selected",
    remove: "Remove",
    postTitle: "Post title",
    preTitle: "Pre title",
    "category must not be empty": "Category must not be empty",
    "startDate must not be empty": "Start date must not be empty",
    "endDate must not be empty": "End date must not be empty",
    "schedule must not be empty": "Schedule must not be empty",
    "tag must not be empty": "Tag must not be empty",
    "principal image must not be empty": "Principal image must not be empty",
    "principal image": "Principal image",
    "event created": "Event created",
    "event updated": "Event updated",
    "user created": "User created",
    "user updated": "User updated",
    users: "Users",
    search: "Search",
    "thumbnail image": "Thumbnail image",
    fullname: "Fullname",
    "no data": "No data",
    "link to meet": "Link to meet",
    "assigned professional must not be empty":
      "Assigned professional must not be empty",
    "assigned center must not be empty": "Assigned center must not be empty",
    "level tests": "Level tests",
    total: "Total",
    exit: "Exit",
    dashboard: "Dashboard",
    "insert meet data here...": "Insert meet data here...",
    "status must not be empty": "Status must not be empty",
    published: "Published",
    draft: "Draft",
    outstanding: "Outstanding",
    resume: "Resume",
    "insert a summary of the event": "Insert a summary of the event",
    "limit summary to 250 characters": "Limit summary to 250 characters",
    "signature creator": "Signature creator",
    position: "Position",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    youtube: "Youtube",
    logo: "Logo",
    download: "Download",
    "copy code": "Copy code",
    save: "Save",
    "save as": "Save as...",
    "signature created": "Signature created",
    "signature updated": "Signature updated",
    name: "Name",
    "signature deleted": "Signature deleted",
    linkedin: "Linkedin",
    "targets page": "Targets page",
    targets: "Targets",
    "lutece targets": "LUTECE targets",
    "selected targets": "Selected targets",
    "target assigned": "Target assigned",
    "target removed": "Target removed",
    "Select the target audience visible on the page.":
      "Select the target audience visible on the page.",
    "level assignment": "Level assignment",
    "Select the target audience and then assign the corresponding levels.":
      "Select the target audience and then assign the corresponding levels.",
    "level list": "Level list",
    "level list in": "Level list in",
    "The selected target audience has no assigned levels.":
      "The selected target audience has no assigned levels.",
    "You must select a target.": "You must select a target.",
    "assign new level": "Assign new level",
    levels: "Levels",
    add: "Add",
    "level assigned": "Level assigned",
    "level removed": "Level removed",
    "courses page": "Courses page",
    "courses list": "Courses list",
    "courses list in": "Courses list in",
    "assign new course": "Assign new course",
    courses: "Courses",
    "course assigned": "Course assigned",
    "course removed": "Course removed",
    code: "Code",
    headquarter: "Headquarter",
    turn: "Turn",
    frequency: "Frequency",
    classroom: "Classroom",
    initialDate: "Initial date",
    quota: "Quota",
    enrolled: "Enrolled",
    "level updated": "Level updated",
    "target updated": "Target updated",
    "new name": "New name",
    description: "Description",
    "groups list in": "Groups list in",
    "groups list": "groups list",
    "The selected target audience has no assigned groups.":
      "The selected target audience has no assigned groups.",
    "assign new group": "Assign new group",
    "without group": "Without group",
    "assign group": "Assign group",
    pages: "Pages",
    "pages page": "Pages",
    "path must not be empty": "Path must not be empty",
    "invalid slug": "Invalid slug",
    "name must not be empty": "Name must not be empty",
    path: "Path",
    type: "Type",
    "section created": "Section created",
    "section edited": "Section edited",
    "config section": "Config section",
    homeResume: "Home Resume",
    textSection: "Text Section",
    "insert text here...": "Insert text here...",
    "button text": "Button text",
    link: "Link",
    gallery: "Gallery",
    "edit content": "Edit content",
    "add content": "Add content",
    slides: "Slides",
    pretitle: "Pre title",
    posttitle: "Post title",
    "section saved": "Section saved",
    "link must not be empty": "Link must not be empty",
    "section type": "Section type",
    order: "Order",
    text: "Text",
    button: "Button",
    action: "action",
    subtitle: "Subtitle",
    question: "Question",
    "insert response here...": "Insert response here...",
    "questions list": "Questions list",
    "add question": "Add question",
    "question editor": "Question editor",
    frequentQuestions: "Frequent questions",
    "save question": "Save question",
    "alter text must not be empty": "Alter text must not be empty",
    "alter text": "Alter text",
    "notification image": "Notification image",
    message: "Message",
    image: "Image",
    "invert columns": "Invert columns",
    "back to pages": "Back to pages",
    Sections: "Sections",
    "new section": "New section",
    lightBoxGallery: "Lightbox gallery images",
    "no configuration": "This section don't need configuration",
    coursesOffered: "Courses offered",
    cards: "Cards",
    cardlist: "Cards list",
    src: "Source map",
    audiences: "Audiences",
    origin: "Origin",
    createdAt: "Created at",
    export: "Export",
    contactDetailTitle: "Contact details",
    mailTo: "Mail to",
    lastName: "Lastname",
    idLutece: "Course ID Lutece",
    level: "Level",
    contacts: "Contacts",
  },
};

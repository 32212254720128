import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const SubTitle = styled.h4`
  width: 100%;
  border-bottom: 0.5px solid #a4a4a4;
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 5px 0;
`;

const ContentItemData = styled.div`
  margin-bottom: 10px;
`;

const ContactModalDetail = ({ contactData, handleClose }) => {
  return (
    <Root>
      <Title>{translate("contactDetailTitle")}</Title>
      <SubTitle>{translate("name")}</SubTitle>
      <ContentItemData>
        {contactData?.firstname || contactData?.lastname
          ? `${contactData?.firstname} ${contactData?.lastname}`
          : "---"}
      </ContentItemData>
      <SubTitle>{translate("phone")}</SubTitle>
      <ContentItemData>{contactData?.phone ?? "---"}</ContentItemData>
      <SubTitle>{translate("mailto")}</SubTitle>
      <ContentItemData>{contactData?.mailto ?? "---"}</ContentItemData>
      <SubTitle>{translate("sede")}</SubTitle>
      <ContentItemData>{contactData?.sede ?? "---"}</ContentItemData>
      <SubTitle>{translate("idLutece")}</SubTitle>
      <ContentItemData>{contactData?.idLutece ?? "---"}</ContentItemData>
      <SubTitle>{translate("level")}</SubTitle>
      <ContentItemData>{contactData?.level ?? "---"}</ContentItemData>
      <SubTitle>{translate("content")}</SubTitle>
      <ContentItemData
        dangerouslySetInnerHTML={{ __html: contactData?.content ?? "---" }}
      />
    </Root>
  );
};

export default ContactModalDetail;

export async function getProductCategory() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(productCategoryData);
    }, 1000);
  });
}

const productCategoryData = [
  { name: "Nivel 1", value: 1200 },
  { name: "Nivel 2", value: 800 },
  { name: "Nivel 3", value: 500 },
  { name: "Nivel 4", value: 900 },
];

import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import axios from "axios";
import { API_URL_LUTECE } from "../../config";
import SelectInput from "../inputs/SelectInput";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.accent};
  border: none;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
  cursor: pointer;
`;

const AssignLevel = ({ assignedLevels, selectedTarget, handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const { token } = useAuth();
  const userData = decodeToken(token);

  const [selectedLevel, setSelectedLevel] = React.useState(null);
  const [levelList, setLevelList] = React.useState(null);

  const getLevelList = () => {
    const assignedLevelsList = assignedLevels.map(al => parseInt(al.idLutece));
    axios
      .get(`${API_URL_LUTECE}/levels`)
      .then((result) => {
        setLevelList(result.data.filter(d => !assignedLevelsList.includes(parseInt(d.idNivel))).map(r => {
          return {
            idNivel: r.idNivel,
            mnemonico: r.mnemonico,
            numNivel: r.numNivel,
            descripcion: r.descripcion.trim(),
            name: `${r.descripcion.trim()} (${r.mnemonico} ${r.numNivel})`,
            value: r.idNivel,
          }
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAutocomplete = (name, value) => {
    setSelectedLevel(value);
  };

  const handleSubmit = () => {
    const newLevel = levelList.find(l => l.value === selectedLevel.value);
    if(selectedTarget){
      const formdata = {
        target: selectedTarget.id,
        idLutece: newLevel.idNivel,
        mnemonic: newLevel.mnemonico,
        numberLevel: newLevel.numNivel,
        description: newLevel.descripcion,
        rename: `${newLevel.descripcion} (${newLevel.mnemonico} ${newLevel.numNivel})`,
        createdUser: userData?.id,
      };
      axios
        .post(`${API_URL_LUTECE}/levels`, formdata, {})
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "level assigned" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            handleClose();
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  React.useEffect(() => {
    getLevelList();
  }, []);

  return (
    <Root>
      {levelList ? (
        <SelectInput
          value={selectedLevel}
          fieldlabel="name"
          fieldvalue="value"
          data={levelList}
          label={intl.formatMessage({ id: "levels" })}
          name="selectedLevel"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          required
        />
      ) : (
        <span>Cargando...</span>
      )}

      <StyledButton onClick={handleSubmit}>{translate("add")}</StyledButton>
    </Root>
  );
};

export default AssignLevel;

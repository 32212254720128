import styled from "styled-components";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import translate from "../../providers/i18n/translate";
import DuoIcon from "@mui/icons-material/Duo";
import AccessTests from "../reservations/AccessTests";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  grid-column: 1/4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 10px 5px 10px;
  font-size: 1.5rem;
  order: 0;
`;

const ProfessionalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  order: 3;
  @media (min-width: 768px) {
    order: inherit;
  }
`;

const ParticipantContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  order: 2;
  @media (min-width: 768px) {
    order: inherit;
  }
`;

const Avatar = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  svg {
    width: 90%;
    height: 90%;
  }
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  order: 1;
  @media (min-width: 768px) {
    order: inherit;
  }
`;

const DataProfessional = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  @media (min-width: 768px) {
    align-items: flex-end;
  }
`;

const DataParticipant = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const Title = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Status = styled.div`
  color: ${(props) => props.theme.palette.details};
  text-transform: uppercase;
`;

const Description = styled.div`
  color: ${(props) => props.theme.palette.details};
  font-size: 0.875rem;
`;

const Meet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Data = styled.div``;

const ShowDataEvent = ({ selectedEvent }) => {
  return (
    <Root>
      <CenterContainer>{selectedEvent.assignedCenter.name}</CenterContainer>
      <ParticipantContainer>
        <Avatar>
          <AssignmentIndIcon />
        </Avatar>
        {selectedEvent.assignedParticipant ? (
          <DataParticipant>
            <Title>{translate("assigned participant")}</Title>
            <Data>
              <strong>{selectedEvent.assignedParticipant.fullname}</strong>
            </Data>
            <Data>
              <a
                href={`mailto:${selectedEvent.assignedParticipant.email}`}
                target="_blank"
              >
                {selectedEvent.assignedParticipant.email}
              </a>
            </Data>
            <Data>
              <span>{selectedEvent.assignedParticipant.phone}</span>
            </Data>
          </DataParticipant>
        ) : (
          <DataParticipant>
            <Title>{translate("no data")}</Title>
          </DataParticipant>
        )}
      </ParticipantContainer>
      <DescriptionContainer>
        <Status>{translate(selectedEvent.status)}</Status>
        {selectedEvent.description ? (
          <Description
            dangerouslySetInnerHTML={{ __html: selectedEvent.description }}
          />
        ) : null}
        {selectedEvent.meet ? (
          <Meet>
            <a href={selectedEvent.meet} target="_blank">
              <DuoIcon /> {translate("link to meet")}
            </a>
          </Meet>
        ) : null}
        {selectedEvent.assignedParticipant ? (
          <>
            <Title>{translate("level tests")}</Title>
            <AccessTests participant={selectedEvent.assignedParticipant.id} />
          </>
        ) : null}
      </DescriptionContainer>
      <ProfessionalContainer>
        <DataProfessional>
          <Title>{translate("assigned professional")}</Title>
          <Data>
            <strong>{selectedEvent.assignedProfessional.fullname}</strong>
          </Data>
          <Data>
            <a
              href={`mailto:${selectedEvent.assignedProfessional.email}`}
              target="_blank"
            >
              {selectedEvent.assignedProfessional.email}
            </a>
          </Data>
          <Data>
            <span>{selectedEvent.assignedProfessional.phone}</span>
          </Data>
        </DataProfessional>
        <Avatar>
          <PermContactCalendarIcon />
        </Avatar>
      </ProfessionalContainer>
    </Root>
  );
};

export default ShowDataEvent;

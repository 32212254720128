import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { API_URL } from "../../config";
import Question from "./Question";
import { Rating } from "@mui/material";

const Root = styled.div`
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(210mm + 100px);
  height: 80vh;
  background: #fff;
  color: ${(props) => props.theme.palette.alternative_principal};
`;

const DocumentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => `${props.theme.palette.details}90`};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.alternative_details};
  }
`;

const Document = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
`;

const PrintButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #40cbe6;
  border-radius: 35px;
  background-color: #40cbe6;
  color: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  align-self: flex-end;
  z-index: 9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  user-select: none;
  svg {
    margin-right: 5px;
  }
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-bottom: 2px solid ${props => `${props.theme.palette.details}50`};
  padding: 20px;
`;

const TestTitle = styled.h2`
  font-size: 2rem;
  margin: 10px 0 5px 0;
`

const TestDescription = styled.small`
  color: ${props => props.theme.palette.details};
  font-size: 1.15rem;
`

const Subtitle = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0 5px 0;
  font-size: 1.5rem;
`

const ContentLogo = styled.div`
  position: relative;
  display: flex;
  width: 90%;
  img {
    cursor: pointer;
    object-fit: contain;
    object-position: center;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
  }
  img:hover {
    transform: scale(1.1);
  }
`;

const IntroHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
`;

const GridLogo = styled.div`
position: relative;
display: flex;
flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ParticipantData = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
  gap: 0 5px;
`;

const ContentTest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
`;



const TestReport = ({ participant, testId, testData }) => {
  const intl = useIntl();

  const theme = React.useContext(ThemeContext);

  const [test, setTest] = React.useState();

  const [correctAnswers, setCorrectAnswers] = React.useState(0);

  const getTestData = () => {
    axios
      .get(`${API_URL}/quizanswers?quiz=${testId}&participant=${participant}`)
      .then((result) => {
        setTest(result.data.rows);
        const correctCount = getCountCorrectAnswers(result.data.rows);
        setCorrectAnswers(correctCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountCorrectAnswers = (answersList) => {
    let count = 0;
    answersList.forEach(element => {
      const participantAnswer = JSON.parse(element.answers[0]);
      if(participantAnswer.correct){
        count++;
      }
    });
    return count;
  }

  React.useEffect(() => {
    if (!test) {
      getTestData();
    }
  }, []);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Root>
      <PrintButton onClick={handlePrint}>{translate("print")}</PrintButton>
      {test ? (
        <DocumentContainer>
          <Document ref={componentRef}>
            <Header>
            <GridLogo>
                <ContentLogo>
                  <img
                    src="/logo.svg"
                    alt="Logo Alianza Francesa"
                    width="100%"
                    height="100%"
                  />
                </ContentLogo>
                <TestTitle>{testData?.name}</TestTitle>
                <TestDescription dangerouslySetInnerHTML={{__html: testData?.description}} />
              </GridLogo>
              <IntroHeader>
              
                <Subtitle>Datos personales:</Subtitle>
                <ParticipantData>
                  <strong>Nombres:</strong>
                  {` ${test[0].participant.firstname}`}
                </ParticipantData>
                <ParticipantData>
                  <strong>Apellidos:</strong>
                  {` ${test[0].participant.lastname}`}
                </ParticipantData>
                <ParticipantData>
                  <strong>Correo electrónico:</strong>
                  {` ${test[0].participant.email}`}
                </ParticipantData>
                <ParticipantData>
                  <strong>Teléfono:</strong>
                  {` ${test[0].participant.phone}`}
                </ParticipantData>
                <ParticipantData>
                  <strong>Respuestas correctas:</strong>
                  {` ${correctAnswers}/${test.length}`}
                </ParticipantData>
                <Rating name="read-only" value={correctAnswers * 5 / test.length} readOnly />
              </IntroHeader>
              
            </Header>
            <ContentTest>
              {
                test ?
                test.map((testQuestion, index) => 
                  <Question key={`${index}_${testQuestion.question.name}`} data={testQuestion} />
                )
                : null
              }
            </ContentTest>
          </Document>
        </DocumentContainer>
      ) : null}
    </Root>
  );
};

export default TestReport;

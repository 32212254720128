import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import AdminNav from "../navbars/adminnav";

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    @media (min-width: 1024px) {
        display: grid;
        flex-direction: inherit;
        grid-template-columns: min-content 1fr;    
        grid-template-rows: inherit;
    }
`

const Content = styled.div`
    position: relative;
    display: grid;
    grid-template-rows: 50px 1fr;
    width: 100%;
    height: 100%;
    padding: 50px 0 0 0;
    box-sizing: border-box;
    max-height: 100vh;
    @media (min-width: 1024px) {
        padding: 0;
        height: 100%;
    }
`

const Header = styled.header`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.palette.principal};
`

const Body = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @media (min-width: 1024px) {
        
    }
`

const Footer = styled.footer`
    display: flex;
    width: 100%;
    height: 100%;
`

const WebLayout = () => {

    const [ openNav, setOpenNav ] = React.useState(false);

    const onSwitchNav = () => {
        setOpenNav(!openNav);
    }

    const closeNav = () => {
        setOpenNav(false);
    }

    return (
        <Root>
            <AdminNav openNav={openNav} onSwitchNav={onSwitchNav} closeNav={closeNav} />
            <Content>
                <Header>
                    
                </Header>
                <Body>
                    <Outlet />
                </Body>
            </Content>
        </Root>
    );
}

export default WebLayout;
import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import SelectInput from "../inputs/SelectInput";
import { API_URL } from "../../config";
import axios from "axios";
import BasicEditor from "../textEditors/basicEditor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";
import InputError from "./InputError";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 20px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0 0 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:disabled {
    color: #fff;
    background-color: grey;
  }
`;

const StyledTextfield = styled(TextField)`
  width: ${(props) => (props.width ? props.width : "inherit")};
`;

const InputGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledEditIcon = styled.div`
  position: absolute;
  right: 100px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#000")};
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
    color: blue;
  }
`;

const StyledDeleteIcon = styled.div`
  position: absolute;
  right: 60px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#000")};
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
    color: red;
  }
`;

const StyledExitIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#000")};
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
    color: #000;
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const AssociatedMeet = styled.div`
  width: 100%;
  height: 100%;
`;

const NewEvent = ({ start, end, handleClose, eventData }) => {
  const TURN_TYPE = "turn";

  const statusOptions = {
    free: "free",
    assigned: "assigned",
    overdue: "overdue",
    completed: "completed",
  };

  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const { token } = useAuth();

  const loggedUser = decodeToken(token);

  const [errors, setErrors] = React.useState({
    assignedProfessional: {
      value: false,
      text: intl.formatMessage({
        id: "assigned professional must not be empty",
      }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
    assignedCenter: {
      value: false,
      text: intl.formatMessage({ id: "assigned center must not be empty" }),
      validate: (value) => value == null, // returns true if there is an error and false if there is not
    },
  });

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [edit, setEdit] = React.useState(eventData ? false : true);

  const dispatch = useDispatch();

  const { loadingAction } = bindActionCreators(actionCreators, dispatch);

  const [newEvent, setNewEvent] = React.useState(
    eventData
      ? {
          id: eventData.id,
          start: new Date(eventData.start),
          end: new Date(eventData.end),
          taskType: eventData.taskType,
          status: eventData.status,
          assignedCenter: eventData.assignedCenter?.id,
          assignedProfessional: eventData.assignedProfessional?.id,
          assignedParticipant: eventData.assignedParticipant?.id,
          isAllDay: false,
          description: eventData.description,
          meet: eventData.meet,
        }
      : {
          start: start || new Date(),
          end: end || new Date(),
          taskType: TURN_TYPE,
          status: statusOptions.free,
          assignedCenter: null,
          assignedProfessional: null,
          assignedParticipant: null,
          isAllDay: false,
          description: "",
          meet: null,
        }
  );

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(newEvent[err]);
      }
      const val = errors[err].validate(newEvent[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setEdit(!edit);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      const url = `${API_URL}/tasks/${newEvent.id}`;
      axios
        .delete(url)
        .then((response) => {
          handleClose();
          /* alert(intl.formatMessage({ id: 'successful editing' })); */
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({
      ...newEvent,
      [name]: value,
    });
  };

  const handleChangeDateStart = (newValue) => {
    setNewEvent({
      ...newEvent,
      start: newValue,
    });
  };

  const handleChangeDateEnd = (newValue) => {
    setNewEvent({
      ...newEvent,
      end: newValue,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setNewEvent({
      ...newEvent,
      [name]: value,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setNewEvent({
      ...newEvent,
      description: dataeditor,
    });
  };

  const handleEditorMeet = (e, dataeditor) => {
    setNewEvent({
      ...newEvent,
      meet: dataeditor,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    if (validateForm()) {
      loadingAction(loadingConstants.WAIT);
      var formdata = new FormData();
      formdata.append("description", newEvent.description);
      formdata.append("meet", newEvent.meet);
      formdata.append("startDate", newEvent.start);
      formdata.append("endDate", newEvent.end);
      formdata.append("taskType", newEvent.taskType);
      formdata.append(
        "status",
        newEvent.assignedParticipant ? statusOptions.assigned : newEvent.status
      );
      if (newEvent.assignedProfessional) {
        formdata.append(
          "assignedProfessional",
          typeof newEvent.assignedProfessional === "object"
            ? newEvent.assignedProfessional.value
            : newEvent.assignedProfessional
        );
      }
      if (newEvent.assignedParticipant) {
        formdata.append(
          "assignedParticipant",
          typeof newEvent.assignedParticipant === "object"
            ? newEvent.assignedParticipant.value
            : newEvent.assignedParticipant
        );
      }
      if (newEvent.assignedCenter) {
        formdata.append(
          "assignedCenter",
          typeof newEvent.assignedCenter === "object"
            ? newEvent.assignedCenter.value
            : newEvent.assignedCenter
        );
      }
      if (eventData) {
        formdata.append("updatedUser", loggedUser?.id);
        const url = `${API_URL}/tasks/${newEvent.id}`;
        axios
          .put(url, formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            loadingAction(loadingConstants.READY);
            handleClose();
            /* alert(intl.formatMessage({ id: "successful editing" })); */
          })
          .catch((error) => {
            loadingAction(loadingConstants.READY);
            console.log(error);
          });
      } else {
        formdata.append("createdUser", loggedUser?.id);
        const url = `${API_URL}/tasks`;
        axios
          .post(url, formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            loadingAction(loadingConstants.READY);
            handleClose();
            /* alert(intl.formatMessage({ id: "successful creation" })); */
          })
          .catch((error) => {
            loadingAction(loadingConstants.READY);
            console.log(error);
          });
      }
    }
  };

  const getProfessionalData = () => {
    axios
      .get(`${API_URL}/professionals/usermanagerid/${loggedUser?.id}`)
      .then((res) => {
        const professional = res?.data;
        setNewEvent({
          ...newEvent,
          meet: professional?.associatedMeet,
        });
      });
  };

  React.useEffect(() => {
    if (!newEvent.meet || newEvent.meet === "") {
      getProfessionalData();
    }
    setEditorLoaded(true);
  }, []);

  return (
    <Root>
      {eventData && (
        <StyledEditIcon onClick={handleEdit} color={theme.palette.details}>
          <EditIcon />
        </StyledEditIcon>
      )}
      {eventData && (
        <StyledDeleteIcon onClick={handleDelete} color={theme.palette.details}>
          <DeleteForeverIcon />
        </StyledDeleteIcon>
      )}
      <StyledExitIcon onClick={handleClose} color={theme.palette.details}>
        <CloseIcon />
      </StyledExitIcon>
      <Title
        borderColor={theme.palette.principal}
        color={theme.palette.principal}
      >
        {eventData ? translate("edit event") : translate("new event")}
      </Title>
      <InputGroup>
        <LocalizationProvider dateAdapter={DateAdapter}>
          {/* <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'start' })}
                        value={newEvent.start}
                        onChange={handleChangeDateStart}
                        disabled={!edit}
                    /> */}
          {/* <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'end' })}
                        value={newEvent.end}
                        onChange={handleChangeDateEnd}
                        disabled={!edit}
                    /> */}
        </LocalizationProvider>
      </InputGroup>
      <InputData>
        <SelectInput
          value={newEvent.assignedProfessional}
          fieldlabel="fullname"
          fieldvalue="id"
          dataurl={`${API_URL}/professionals/list`}
          label={intl.formatMessage({ id: "assigned professional" })}
          name="assignedProfessional"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          disabled={!edit}
          required
        />
        <InputError show={errors.assignedProfessional.value}>
          {errors.assignedProfessional.text}
        </InputError>
      </InputData>
      <InputData>
        <SelectInput
          value={newEvent.assignedCenter}
          fieldlabel="name"
          fieldvalue="id"
          dataurl={`${API_URL}/centers/list`}
          label={intl.formatMessage({ id: "assigned center" })}
          name="assignedCenter"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          disabled={!edit}
          required
        />
        <InputError show={errors.assignedCenter.value}>
          {errors.assignedCenter.text}
        </InputError>
      </InputData>
      <SelectInput
        value={newEvent.assignedParticipant}
        fieldlabel="fullname"
        fieldvalue="id"
        dataurl={`${API_URL}/participants/list`}
        label={intl.formatMessage({ id: "assigned participant" })}
        name="assignedParticipant"
        onChange={handleChangeAutocomplete}
        width="100%"
        margin="dense"
        size="small"
        disabled={!edit}
      />
      <BasicEditor
        name="description"
        value={newEvent.description}
        onChange={handleEditor}
        editorLoaded={editorLoaded}
        maxContentHeight="100px"
        minContentHeight="100px"
        placeholder="Ingrese aquí algún comentario si lo desea..."
        readOnly={!edit}
      />
      <AssociatedMeet>
        <BasicEditor
          name="meet"
          value={newEvent.meet}
          onChange={handleEditorMeet}
          editorLoaded={editorLoaded}
          maxContentHeight="300px"
          minContentHeight="250px"
          placeholder={intl.formatMessage({
            id: "insert meet data here...",
          })}
        />
      </AssociatedMeet>
      <Button
        disabled={!edit}
        bg={theme.palette.accent}
        color={theme.palette.base}
        onClick={handleAction}
      >
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default NewEvent;

import React from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

/* Styled components */

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const SelectInput = ({
  label,
  name,
  value,
  onChange,
  width,
  size,
  margin,
  fieldlabel,
  fieldvalue,
  fieldextra1,
  fieldextra2,
  data,
  dataurl,
  required,
  disabled,
  subfield,
  withCredentials,
  translateLabels
}) => {
  const [options, setOptions] = React.useState(null);

  const [search, setSearch] = React.useState("");

  const intl = useIntl();

  const handleChange = (event, newValue) => {
    onChange(name, newValue);
  };

  React.useEffect(() => {
    if (!options) {
      if (!data) {
        axios
          .get(dataurl, { withCredentials: withCredentials ? true : false })
          .then((result) => {
            const dataRows = subfield ? result.data[subfield] : result.data;
            const opts = dataRows.map((opt) => {
              return {
                label: fieldlabel ? fieldlabel.split(',').map(fl => opt[fl]).join(' ') : null,
                value: fieldvalue ? opt[fieldvalue] : null,
                extra1: fieldextra1 ? opt[fieldextra1] : null,
                extra2: fieldextra2 ? opt[fieldextra2] : null,
              };
            });
            setOptions(opts);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const opts = data.map((opt) => {
          return {
            label: fieldlabel ? opt[fieldlabel] : null,
            value: fieldvalue ? opt[fieldvalue] : null,
            extra1: fieldextra1 ? opt[fieldextra1] : null,
            extra2: fieldextra2 ? opt[fieldextra2] : null,
          };
        });
        setOptions(opts);
      }
    }
  }, [options]);

  return options ? (
    <StyledAutocomplete
      value={
        typeof value === "object"
          ? value
          : options.filter((o) => o.value == value)[0]
      }
      onChange={handleChange}
      inputValue={search}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      width={width}
      size={size}
      disabled={disabled}
      id={`id_autocomplete_${name}`}
      options={options}
      required={required}
      getOptionLabel={(option) =>
        translateLabels ? intl.formatMessage({ id: option.label, defaultMessage: option.label }) : option.label
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={translateLabels ? translate(label) : label}
          variant="outlined"
          required={required}
          margin={margin}
          disabled={disabled}
        />
      )}
    />
  ) : (
    <Box sx={{ width: "45%" }}>
      <Skeleton variant="h2" />
    </Box>
  );
};

export default SelectInput;

import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../providers/theme";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useIntl } from "react-intl";

const Root = styled.div`
  position: relative;
`;

const StyledIcon = styled(FileDownloadIcon)`
  cursor: pointer;
  margin: 0 10px;
  color: ${(props) => (props.color ? props.color : "#000")};
`;

const ExportButton = ({ action }) => {
  const theme = React.useContext(ThemeContext);
  const intl = useIntl();
  const handleAction = (e) => {
    e.preventDefault();
    action();
  };

  return (
    <Root
      title={intl.formatMessage({
        id: "export",
      })}
    >
      <StyledIcon onClick={handleAction} color={theme.palette.principal} />
    </Root>
  );
};

export default ExportButton;

import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../providers/theme";
import ItemList from "./ItemList";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import translate from "../../../providers/i18n/translate";
import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/authentication";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import SchoolIcon from "@mui/icons-material/School";
import PagesIcon from "@mui/icons-material/Pages";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ContactsIcon from "@mui/icons-material/Contacts";

const menuList = [
  {
    name: translate("dashboard"),
    link: "/admin/dashboard",
    icon: <GridViewIcon />,
  },
  {
    name: translate("users"),
    link: "/admin/users",
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    name: translate("pages page"),
    link: "/admin/pages",
    icon: <PagesIcon />,
  },
  {
    name: translate("calendar"),
    link: "/admin/calendar",
    icon: <CalendarTodayIcon />,
  },
  {
    name: translate("reservations"),
    link: "/admin/reservations",
    icon: <CalendarMonthIcon />,
  },
  {
    name: translate("posts"),
    link: "/admin/posts",
    icon: <NewspaperIcon />,
  },
  {
    name: translate("signature creator"),
    link: "/admin/signaturecreator",
    icon: <DocumentScannerIcon />,
  },
  {
    name: translate("targets page"),
    link: "/admin/targets",
    icon: <ModeStandbyIcon />,
  },
  {
    name: translate("courses page"),
    link: "/admin/courses",
    icon: <SchoolIcon />,
  },
  {
    name: translate("audiences"),
    link: "/admin/audiences",
    icon: <AutoStoriesIcon />,
  },
  {
    name: translate("contacts"),
    link: "/admin/contacts",
    icon: <ContactsIcon />,
  },
  /* {
        name: translate("settings"),
        link: "/admin/settings",
        icon: <SettingsIcon />
    } */
];

const Root = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.openNav ? "100%" : "50px")};
  display: grid;
  grid-template-rows: 50px 1fr;
  overflow-y: hidden;
  background-color: ${(props) => (props.bg ? props.bg : "#000")};
  transition: height 500ms ease-in-out;
  z-index: 9999;
  @media (min-width: 1024px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${(props) => (props.openNav ? props.openWidth : props.closeWidth)};
    height: 100%;
    top: inherit;
    left: inherit;
    z-index: inherit;
    overflow-x: hidden;
    overflow-y: inherit;
    grid-template-rows: inherit;
    transition: width 500ms ease-in-out;
  }
`;

const Switch = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12.5px;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
  svg {
    transition: all 200ms ease-in-out;
    color: ${(props) => (props.color ? props.color : "#FFF")};
  }
  @media (min-width: 1024px) {
    svg:hover {
      transform: scale(1.1);
    }
  }
`;

const MenuList = styled.ul`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  @media (min-width: 1024px) {
    display: flex;
    padding: 0;
  }
`;

const Logout = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12.5px;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
  svg {
    transition: all 200ms ease-in-out;
    color: ${(props) => (props.color ? props.color : "#FFF")};
  }
  span {
    color: ${(props) => (props.color ? props.color : "#FFF")};
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
    opacity: ${(props) => (props.showname ? 1 : 0)};
    margin: 0 0 0 10px;
  }
  @media (min-width: 1024px) {
    svg:hover {
      transform: scale(1.1);
    }
  }
`;

const AdminNav = ({ openNav, onSwitchNav, closeNav }) => {
  const intl = useIntl();

  let navigate = useNavigate();

  const { onLogout } = useAuth();

  const OPEN_NAV_WIDTH = "200px";
  const CLOSE_NAV_WIDTH = "50px";

  const logout = () => {
    onLogout(() => navigate("/"));
  };

  const theme = React.useContext(ThemeContext);

  return (
    <Root
      openNav={openNav}
      openWidth={OPEN_NAV_WIDTH}
      closeWidth={CLOSE_NAV_WIDTH}
      bg={theme.palette.principal}
    >
      <Switch openNav={openNav}>
        {openNav ? (
          <ArrowBackIcon onClick={onSwitchNav} />
        ) : (
          <MenuIcon onClick={onSwitchNav} />
        )}
      </Switch>
      <MenuList openNav={false}>
        {menuList &&
          menuList.map((item, index) => (
            <ItemList
              key={`item_${index}_${item.name}`}
              item={item}
              color={theme.palette.base}
              closeNav={closeNav}
              activecolor={theme.palette.accent}
              showname={openNav ? 1 : 0}
            />
          ))}
      </MenuList>
      <Tooltip
        title={intl.formatMessage({ id: "exit" })}
        placement={openNav ? "left" : "right"}
      >
        <Logout onClick={logout} showname={openNav ? 1 : 0}>
          <LogoutIcon />
          <span>{translate("exit")}</span>
        </Logout>
      </Tooltip>
    </Root>
  );
};

export default AdminNav;

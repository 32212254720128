import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import axios from "axios";
import { API_URL_LUTECE } from "../../config";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { TextField } from "@mui/material";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const StyledTextfield = styled(TextField)`
  width: ${(props) => (props.width ? props.width : "inherit")};
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.accent};
  border: none;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
  cursor: pointer;
`;

const AssignGroup = ({ selectedTarget, handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const { token } = useAuth();
  const userData = decodeToken(token);

  const [inputData, setInputData] = React.useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (selectedTarget) {
      const formdata = {
        target: selectedTarget.id,
        ...inputData,
        createdUser: userData?.id,
      };
      axios
        .post(`${API_URL_LUTECE}/groups`, formdata, {})
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "group assigned" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            handleClose();
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  return (
    <Root>
      <StyledTextfield
        id="name"
        name="name"
        onChange={handleChange}
        value={inputData.name}
        label={intl.formatMessage({ id: "name" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
      />
      <StyledTextfield
        id="description"
        name="description"
        onChange={handleChange}
        value={inputData.description}
        label={intl.formatMessage({ id: "description" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
      />
      <StyledButton onClick={handleSubmit}>{translate("add")}</StyledButton>
    </Root>
  );
};

export default AssignGroup;

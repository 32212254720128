import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import Event from './Event';

/* Styled components */

const Root = styled.div`
    position: relative;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    border-radius: 20px;
    padding: 20px;
    @media (min-width: 1024px) {
        display: flex;
    }
`

const Title = styled.h2`
    width: 100%;
    height: 30px;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    font-weight: 600;
`

const Events = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
`

const EventsDay = ({eventsDay}) => {

    const theme = React.useContext(ThemeContext);

    /* const [ eventsDay, setEventsDay ] = React.useState(null);

    

    React.useEffect(() => {
        if(!eventsDay && events){
            const today = new Date();
            
        }
    }, [eventsDay, events]) */

    return (
        eventsDay &&
        <Root borderColor={theme.palette.principal}>
            <Title color={theme.palette.principal}>{translate("eventsDay")}</Title>
            <Events>
                {
                    eventsDay.map((ed, index) => 
                        <Event key={`event_${index}`} event={ed} />
                    )
                }
            </Events>
        </Root>
    );
}

export default EventsDay;
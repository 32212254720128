import styled from "styled-components";

const Root = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    margin: 5px 0;
    cursor: pointer;
    line-height: 2rem;
    border: none;
    background: none;
    box-shadow: ${props => props.selected ? '0 0 5px rgba(0,0,0,0.5)' : 'none'};
    transition: 300ms;
    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
    }
`

const AssignedLevelToSelect = ({data, onSelect, selected}) => {

    const onSelectTarget = () => {
        if(!selected){
            onSelect(data.id);
        } else if(data.id !== selected.id){
            onSelect(data.id);
        }
    }

    return (
        <Root selected={selected ? selected.id === data.id : false} onClick={onSelectTarget}>
            {data.rename ?? `${data.description} (${data.mnemonic} ${data.numberLevel})`}
        </Root>
    );
}

export default AssignedLevelToSelect;
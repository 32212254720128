import { Navigate } from 'react-router-dom';
import { useAuth } from '.';

const LoggedIn = ({children}) => {
    const { token } = useAuth();
    
    if (token) {
        return <Navigate to="/admin/dashboard" replace />;
    }

    return children;
}

export default LoggedIn;
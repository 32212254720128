import React from "react";
import styled from "styled-components";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useRef, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import translate from "../../providers/i18n/translate";
import AssignGroupToLevel from "../groups/AssignGroupToLevel";
import { Modal } from "@mui/material";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  width: fit-content;
  padding: 4px 16px;
  margin: 5px 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled.span`
  line-height: 2rem;
  margin-right: 10px;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  margin-left: 10px;
  svg {
    color: ${(props) =>
      props.colorbutton ? props.colorbutton : props.theme.palette.principal};
    cursor: pointer;
  }
`;

const StyledButtonGroup = styled.button`
  border: none;
  background: none;
  margin-left: 10px;
  color: ${(props) =>
  props.colorbutton ? props.colorbutton : props.theme.palette.principal};
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.show ? "auto" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  small {
    margin-left: 10px;
  }
`;

const RenameInput = styled.input`
  line-height: 1.5rem;
  margin-left: 10px;
  padding: 0 10px;
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  &:focus {
    box-shadow: 0 0 2px ${(props) => `${props.theme.palette.principal}50`};
  }
`;

const DescriptionInput = styled.textarea`
  line-height: 1.5rem;
  margin-left: 10px;
  padding: 0 10px;
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  &:focus {
    box-shadow: 0 0 2px ${(props) => `${props.theme.palette.principal}50`};
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 0;
`;

const AsignnedGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const AssignedLevel = ({ data, onDelete, onRename, onRedescription, selectedTarget, getAssignedGroups }) => {
  const [rename, setRename] = useState(
    data.rename ?? `${data.description} (${data.mnemonic} ${data.numberLevel})`
  );
  const [redescription, setRedescription] = useState(data.redescription);
  const [showRename, setShowRename] = useState(false);
  const [showRedescription, setShowRedescription] = useState(false);
  const [open, setOpen] = React.useState(false);
  const onAssignGroup = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if(selectedTarget){
      getAssignedGroups(selectedTarget.id);
    }
  };

  const inputRef = useRef();
  const textareaRef = useRef();

  const onDeleteTarget = (e) => {
    onDelete(data.id);
  };

  const onEditName = () => {
    if (showRedescription) {
      setRedescription(data.description);
      setShowRedescription(false);
    }
    setShowRename(!showRename);
    inputRef.current.focus();
  };

  const onEditDescription = () => {
    if (showRename) {
      setRename(data.rename ?? `${data.name} (${data.mnemonic})`);
      setShowRename(false);
    }
    setShowRedescription(!showRedescription);
    textareaRef.current.focus();
  };

  const onChangeRename = (e) => {
    const { value } = e.target;

    setRename(value);
  };

  const onChangeRedescription = (e) => {
    const { value } = e.target;

    setRedescription(value);
  };

  const handleKeyPressRename = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      e.preventDefault();
      onRename(data.id, rename);
      setShowRename(false);
    } else if (key === 27) {
      e.preventDefault();
      setRename(data.rename ?? `${data.name} (${data.mnemonic})`);
      setShowRename(false);
    }
  };

  const handleKeyPressRedescription = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      e.preventDefault();
      onRedescription(data.id, redescription);
      setShowRedescription(false);
    } else if (key === 27) {
      e.preventDefault();
      setRedescription(data.description);
      setShowRedescription(false);
    }
  };

  return (
    <Root>
      <Container>
        <Name>
          {data.rename ??
            `${data.description} (${data.mnemonic} ${data.numberLevel})`}
        </Name>
        <StyledButton colorbutton="red" onClick={onDeleteTarget}>
          <RemoveCircleIcon />
        </StyledButton>
        <Actions>
          <StyledButton onClick={onEditName}>
            <EditIcon />
          </StyledButton>
          <StyledButton onClick={onEditDescription}>
            <DescriptionIcon />
          </StyledButton>
        </Actions>
        <InputContainer show={showRename}>
          <small>{translate("new name")}</small>
          <RenameInput
            ref={inputRef}
            id={`target_rename_input_${data.id}`}
            type="text"
            name="rename"
            value={rename}
            onChange={onChangeRename}
            onKeyDown={handleKeyPressRename}
          />
        </InputContainer>
        <InputContainer show={showRedescription}>
          <small>{translate("description")}</small>
          <DescriptionInput
            ref={textareaRef}
            id={`target_redescription_input_${data.id}`}
            type="text"
            name="redescription"
            value={redescription}
            onChange={onChangeRedescription}
            onKeyDown={handleKeyPressRedescription}
          />
        </InputContainer>
      </Container>
      <AsignnedGroup>
        {data.group?.name ?? (
          <StyledButtonGroup colorbutton="blue" onClick={onAssignGroup}>
            {translate("assign group")}
          </StyledButtonGroup>
        )}
        <Modal open={open} onClose={handleClose}>
          <AssignGroupToLevel selectedLevel={data.id} handleClose={handleClose} />
        </Modal>
      </AsignnedGroup>
    </Root>
  );
};

export default AssignedLevel;

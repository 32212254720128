import { LoadingLayout } from "./loading-layout";
import { LoadingText } from "./loading-text";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  50% {
      opacity: .5;
  }
`;

const IndicatorLoadingWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem /* 4px */;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const Div1 = styled.div`
  width: 2.5rem /* 40px */;
  height: 2.5rem /* 40px */;
  background-color: aliceblue;
  border-radius: 0.25rem /* 4px */;
  margin-bottom: 0.5rem /* 8px */;
`;

const Div2 = styled.div`
  width: 75%;
  height: 1rem /* 16px */;
  background-color: aliceblue;
  border-radius: 0.5rem /* 8px */;
  margin-left: auto;
  margin-right: auto;
`;

const Div3 = styled.div`
  width: 50%;
  height: 1rem /* 16px */;
  background-color: aliceblue;
  border-radius: 0.5rem /* 8px */;
  margin-left: auto;
  margin-right: auto;
`;

export const IndicatorLoading = () => {
  return (
    <LoadingLayout>
      <IndicatorLoadingWrapper>
        <Div1 />
        <Div2 />
        <Div3 />
        <LoadingText />
      </IndicatorLoadingWrapper>
    </LoadingLayout>
  );
};

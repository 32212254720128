import React from "react";
import styled from "styled-components";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import { Table, SearchInput } from "../components/tables/table";
import axios from "axios";
import { API_URL } from "../config";
import {
  formatTimestamp,
  getHourFromTime,
  getMinutesFromTime,
  getStringDate,
} from "../utils/dateUtils";
import AccessTests from "../components/reservations/AccessTests";
import ReservationState from "../components/reservations/ReservationState";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { es } from "react-date-range/dist/locale";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
} from "date-fns";
import { useIntl } from "react-intl";
import SelectInput from "../components/inputs/SelectInput";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 50px;
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "red")};
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  padding: 25px 0 0 0;
`;

const Filters = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
};

const sideBarOptions = () => {
  const customDateObjects = [
    {
      label: "Hoy",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Últimos 7 días",
      range: () => ({
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Últimos 30 días",
      range: () => ({
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Últimos 90 días",
      range: () => ({
        startDate: defineds.startOfLastNintyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Este mes",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: "Mes anterior",
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: "Este año",
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },
    {
      label: "Último año",
      range: () => ({
        startDate: defineds.startOflastYear,
        endDate: defineds.endOflastYear,
      }),
    },
  ];
  return customDateObjects;
};

export default function ReservationsPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const intl = useIntl();

  const [search, setSearch] = React.useState("");

  const [dateFilter, setDateFilter] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [assignedProfessional, setAssignedProfessional] = React.useState(null);

  const columnsTable = [
    {
      title: translate("day"),
      field: "task.startDate",
      order: false,
      width: "10%",
      hidden: false,
      render: (d) => formatTimestamp(d.task.startDate),
    },
    {
      title: translate("hour"),
      field: "task.startDate",
      order: false,
      width: "8%",
      hidden: false,
      render: (d) =>
        `${
          getHourFromTime(d.task.startDate.split("T")[1]) - 3
        }:${getMinutesFromTime(d.task.startDate.split("T")[1])}`,
    },
    {
      title: translate("center"),
      field: "task.assignedCenter",
      width: "14%",
      hidden: false,
      render: (d) => d.task.assignedCenter.name,
    },
    {
      title: translate("firstname"),
      field: "participant.firstname",
      width: "12%",
      hidden: false,
      render: (d) => d.participant.firstname,
    },
    {
      title: translate("lastname"),
      field: "participant.lastname",
      width: "12%",
      hidden: false,
      render: (d) => d.participant.lastname,
    },
    {
      title: translate("email"),
      field: "participant.email",
      width: "12%",
      hidden: false,
      render: (d) => d.participant.email,
    },
    {
      title: translate("phone"),
      field: "participant.phone",
      width: "10%",
      hidden: false,
      render: (d) => d.participant.phone,
    },
    {
      title: translate("tests"),
      field: "participant.id",
      width: "10%",
      hidden: false,
      render: (d) => <AccessTests participant={d.participant.id} />,
    },
    {
      title: translate("status"),
      field: "status",
      width: "10%",
      hidden: false,
      render: (d) => <ReservationState idReservation={d.id} />,
    },
    { title: translate("actions"), actions: true, width: "auto", hidden: true },
  ];

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const sideBar = sideBarOptions();

  const staticRanges = [...createStaticRanges(sideBar)];

  const handleDateFilter = (range) => {
    setDateFilter((state) => [range.selection]);
    setTimeout(() => {
      tableRef.current.clean();
    }, 500);
  };

  const handleChangeAutocomplete = (name, value) => {
    setAssignedProfessional(value);
    setTimeout(() => {
      tableRef.current.clean();
    }, 1000);
  };

  return (
    <Root>
      <Title color={theme.palette.accent} borderColor={theme.palette.details}>
        {translate("reservations")}
      </Title>
      <Filters>
        <DateRangePicker
          ranges={dateFilter}
          onChange={handleDateFilter}
          direction="horizontal"
          locale={es}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          staticRanges={staticRanges}
          inputRanges={[]}
        />
        <SelectInput
          value={assignedProfessional}
          fieldlabel="fullname"
          fieldvalue="id"
          dataurl={`${API_URL}/professionals/list`}
          label={intl.formatMessage({ id: "assigned professional" })}
          name="assignedProfessional"
          onChange={handleChangeAutocomplete}
          width="300px"
          margin="dense"
          size="small"
        />
      </Filters>
      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={{}}
          viewTable="list"
          disablePlain
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_URL}/reservations/list`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                    startBetween: getStringDate(dateFilter[0].startDate),
                    endBetween: getStringDate(dateFilter[0].endDate),
                    professionalId: assignedProfessional?.value,
                  },
                })
                .then((reservations) => {
                  resolve(reservations.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        >
          <SearchInput
            search={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
        </Table>
      </Content>
    </Root>
  );
}

import React from "react";
import styled from "styled-components";
import translate from "../providers/i18n/translate";
import { useAuth } from "../providers/authentication";
import { decodeToken } from "../hooks/jwt";
import axios from "axios";
import { API_URL_LUTECE } from "../config";
import TargetToSelect from "../components/targets/TargetToSelect";
import AssignedLevelToSelect from "../components/levels/AssignedLevelToSelect";
import { useIntl } from "react-intl";
import AssignCourse from "../components/courses/AssignCourse";
import { Modal } from "@mui/material";
import AssignedCourse from "../components/courses/AssignedCourse";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";
import AssignedCourses from "../components/courses/AssignedCourses";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  padding: 20px 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const Title = styled.h1`
  grid-column: 1/3;
`;

const Text = styled.p`
  grid-column: 1/3;
`;

const Subtitle = styled.h2`
  margin-bottom: 10px;
  border-bottom: 1px solid black;
`;

const CoursesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  grid-column: 1/3;
`;

const AssignCourseButton = styled.button`
  width: fit-content;
  padding: 8px 16px;
  border: none;
  color: white;
  background: #006611;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin: 10px 0;
  &:disabled {
    opacity: 0.5;
  }
`;



export default function CoursesPage() {
  const { token } = useAuth();

  const userData = decodeToken(token);
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const [targets, setTargets] = React.useState([]);
  const [assignedLevels, setAssignedLevels] = React.useState([]);
  const [selectedTarget, setSelectedTarget] = React.useState(null);
  const [selectedLevel, setSelectedLevel] = React.useState(null);
  const [assignedCourses, setAssignedCourses] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (selectedLevel) {
        getAssignedCourses(selectedLevel.id);
    }
  };

  const getSelectedTargets = () => {
    axios
      .get(`${API_URL_LUTECE}/targets`)
      .then((result) => {
        setTargets(result.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssignedLevels = (id) => {
    axios
      .get(`${API_URL_LUTECE}/assignedlevels/${id}`)
      .then((result) => {
        setAssignedLevels(result.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssignedCourses = (id) => {
    axios
      .get(`${API_URL_LUTECE}/courses/level/${id}`)
      .then((result) => {
        setAssignedCourses(result.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectTarget = (id) => {
    const target = targets.find((s) => s.id === id);
    setSelectedTarget(target);
    getAssignedLevels(id);
    setSelectedLevel(null);
    setAssignedCourses([]);
  };

  const selectLevel = (id) => {
    const level = assignedLevels.find((l) => l.id === id);
    setSelectedLevel(level);
    getAssignedCourses(id);
  };

  const removeCourse = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL_LUTECE}/courses/${id}`)
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "course removed" }),
            alertConstants.INFO
          );
          setTimeout(() => {
            if(selectedLevel){
                getAssignedCourses(selectedLevel.id);
            }
          }, 500);
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  }

  React.useEffect(() => {
    getSelectedTargets();
  }, []);

  return (
    <Root>
      <Container>
        <Box>
          <Subtitle>{translate("targets")}</Subtitle>
          {targets &&
            targets.map((st, index) => (
              <TargetToSelect
                key={`selected_target_${index}`}
                data={st}
                onSelect={selectTarget}
                selected={selectedTarget}
              />
            ))}
        </Box>
        <Box>
          <Subtitle>
            {selectedTarget
              ? `${intl.formatMessage({ id: "level list in" })} ${
                  selectedTarget.name
                }`
              : translate("level list")}{" "}
          </Subtitle>
          {selectedTarget ? (
            assignedLevels.length === 0 ? (
              <p>
                {translate(
                  "The selected target audience has no assigned levels."
                )}
              </p>
            ) : (
              assignedLevels.map((al, index) => (
                <AssignedLevelToSelect
                  key={`selected_level_${index}`}
                  data={al}
                  onSelect={selectLevel}
                  selected={selectedLevel}
                />
              ))
            )
          ) : (
            <p>{translate("You must select a target.")}</p>
          )}
        </Box>
        <CoursesBox>
          <Subtitle>
            {selectedLevel
              ? `${intl.formatMessage({
                  id: "courses list in",
                })} ${`${selectedLevel.description} (${selectedLevel.mnemonic} ${selectedLevel.numberLevel})`} ${intl.formatMessage(
                  { id: "of" }
                )} ${selectedTarget.name}`
              : translate("courses list")}
          </Subtitle>
          <AssignedCourses data={assignedCourses} onDelete={removeCourse} />
          <AssignCourseButton disabled={selectedLevel ? false : true} onClick={handleOpen}>
            {translate("assign new course")}
          </AssignCourseButton>
          <Modal open={open} onClose={handleClose}>
            <AssignCourse
              assignedCourses={assignedCourses}
              selectedLevel={selectedLevel}
              handleClose={handleClose}
            />
          </Modal>
        </CoursesBox>
      </Container>
    </Root>
  );
}

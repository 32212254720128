import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { I18nProvider, LOCALES } from "./providers/i18n";
import { ThemeProvider, THEMES } from "./providers/theme";
import AdminLayout from "./components/layouts/AdminLayout";
import AdminPage from "./pages/AdminPage";
import NotFoundPage from "./pages/NotFoundPage";
import GlobalStyle from "./globalStyles";
import Login from "./pages/Login";
import Protected from "./providers/authentication/protected";
import AuthProvider from "./providers/authentication";
import UsersPage from "./pages/UsersPage";
import CalendarPage from "./pages/CalendarPage";
import MessageBar from "./components/messageBar";
import ReservationsPage from "./pages/Reservations";
import PostsPage from "./pages/PostsPage";
import NewPost from "./components/post/newPost";
import SignatureCreatorPage from "./pages/SignatureCreatorPage";
import TargetsPage from "./pages/TargetsPage";
import CoursesPage from "./pages/CoursesPage";
import LoggedIn from "./providers/authentication/loggedIn";
import PagesPage from "./pages/PagesPage";
import NewPage from "./components/pages/newPage";
import SectionsPage from "./pages/SectionsPage";
import ConfigSection from "./components/section/configSection";
import AudiencesPage from "./pages/AudiencesPage";
import ContactsPage from "./pages/ContactsPage";

function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider theme={THEMES.default}>
        <I18nProvider locale={LOCALES.SPANISH}>
          <AuthProvider>
            <MessageBar />
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route
                path="/login"
                element={
                  <LoggedIn>
                    <Login />
                  </LoggedIn>
                }
              />
              <Route
                exact
                path="/admin"
                element={
                  <Protected>
                    <AdminLayout />
                  </Protected>
                }
              >
                <Route path="/admin/dashboard" element={<AdminPage />} />
                <Route path="/admin/users" element={<UsersPage />} />
                <Route path="/admin/calendar" element={<CalendarPage />} />
                <Route
                  path="/admin/reservations"
                  element={<ReservationsPage />}
                />
                <Route path="/admin/posts" element={<PostsPage />} />
                <Route path="/admin/posts/:id" element={<NewPost />} />
                <Route path="/admin/newpost" element={<NewPost />} />
                <Route
                  path="/admin/signaturecreator"
                  element={<SignatureCreatorPage />}
                />
                <Route path="/admin/targets" element={<TargetsPage />} />
                <Route path="/admin/courses" element={<CoursesPage />} />
                <Route path="/admin/pages" element={<PagesPage />} />
                <Route path="/admin/newpage" element={<NewPage />} />
                <Route path="/admin/pages/:id" element={<NewPage />} />
                <Route path="/admin/sections" element={<SectionsPage />} />
                <Route path="/admin/sections/:id" element={<ConfigSection />} />
                <Route path="/admin/audiences" element={<AudiencesPage />} />
                <Route path="/admin/contacts" element={<ContactsPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </AuthProvider>
        </I18nProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
